import React from 'react';
import { connect } from 'react-redux';
import { Divider, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import TeamLogo from '../../../components/TeamLogo';
import { bindActionCreators } from 'redux';
import { selectTeam } from '../reducer';
import { browserHistory } from '../../../store';
import BoostGate from '../../../components/boost/BoostGate';
import { IoIosArrowForward } from 'react-icons/io';

class TeamSelectionButton extends React.Component {
    state = {
        anchorEl: null,
        open: false,
    };

    render() {
        const { profile, isMenu } = this.props;

        if (profile?.Teams.length <= 0) {
            return null;
        }

        const themeTextColor = profile?.Settings?.Theme === 'light' ? 'rgba(0, 0, 0, 0.87)' : 'white';

        return (
            <React.Fragment>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        cursor: 'pointer',
                        position: 'relative',
                    }}
                    aria-owns={this.state.open ? 'menu-team-selector' : undefined}
                    aria-haspopup="true"
                    onClick={(event) => {
                        this.setState({
                            anchorEl: event.currentTarget,
                            open: true,
                        });
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: 'bold',
                            fontSize: '1.1rem',
                            width: 'auto',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <TeamLogo url={profile?.Team?.Logo} sx={{ width: 30, height: 30, marginRight: '1.20rem' }} />

                        <Typography
                            noWrap
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.1rem',
                                width: '6rem',
                            }}
                        >
                            {profile?.Team?.Name}
                        </Typography>
                    </div>
                    <IoIosArrowForward color="white" size={20} />
                </div>
                <Menu
                    id="menu-team-selector"
                    anchorEl={this.state.anchorEl}
                    open={this.state.open}
                    onClose={() => {
                        this.handleClose();
                    }}
                    PaperProps={{
                        sx: {
                            minWidth: 200,
                            ml: '13rem',
                            backgroundColor: 'rgb(12, 17, 38)',
                            backgroundImage:
                                'radial-gradient(ellipse farthest-side, rgba(0, 111, 255, 0.08), transparent)',
                            backgroundSize: '100%',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            boxShadow: 3,
                            borderRadius: '10px',
                            border: '2px solid #181D31',
                            mt: '-2.5rem',
                            '@media (max-width: 600px)': {
                                mt: '-4rem',
                                ml: '11rem',
                                width: '13rem',
                            },
                        },
                    }}
                >
                    {profile?.Teams?.map((team) => (
                        <MenuItem key={team.ID} onClick={() => this.selectTeam(team.ID)}>
                            <ListItemIcon>
                                <TeamLogo url={team.Logo} sx={isMenu && { width: '20px', height: '20px' }} />
                            </ListItemIcon>
                            <Typography noWrap sx={{ marginLeft: 2, fontWeight: 700 }}>
                                {team.Name}
                            </Typography>
                        </MenuItem>
                    ))}
                    {!isMenu && <Divider variant="middle" style={{ margin: '10px' }} />}
                    {!isMenu && (
                        <BoostGate
                            type="user"
                            featureId="create_more_teams"
                            requiredPropertyCount={profile?.Teams?.length + 1}
                            labelSx={{
                                top: 6,
                                right: 5,
                            }}
                        >
                            <MenuItem onClick={() => this.createNewTeam()} style={{ justifyContent: 'center' }}>
                                <Typography variant="button">Create New Team</Typography>
                            </MenuItem>
                        </BoostGate>
                    )}
                </Menu>
            </React.Fragment>
        );
    }

    createNewTeam() {
        browserHistory.push('/team/create');
        this.handleClose();
    }

    selectTeam(teamId) {
        const { profile } = this.props;
        if (profile.Team && profile.Team.ID !== teamId) {
            this.props.selectTeam(teamId);
        }
        this.handleClose();
        if (this.props.isMenu) {
            this.props.closeMenuPaper();
        }
    }

    handleClose() {
        this.setState({
            anchorEl: null,
            open: false,
        });
    }
}

const Connected = connect(
    (state) => ({
        profile: state.app.profile,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                selectTeam,
            },
            dispatch
        )
)(TeamSelectionButton);

export default Connected;
