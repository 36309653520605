import React from 'react';
import { render } from 'react-dom';
import { Route, Router, Switch, useLocation } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { createTheme, StyledEngineProvider, ThemeProvider, emphasize } from '@mui/material/styles';
import configureStore, { browserHistory } from './store';
import { unregister } from './registerServiceWorker';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { darkTheme, lightTheme } from './theme';
import axios from 'axios';

import './cropper.css';
import './popup.css';
import './index.css';
import { getGameForProfile } from './utils/games';
import { handleError } from './components/snackbar/reducer';
import Loadable from 'react-loadable';
import Loading from './components/Loading';
import CssBaseline from '@mui/material/CssBaseline';
import { setBoostFeatures, setGiveAways } from './containers/app/reducer';
import AdContextProvider from './components/ads/AdContextProvider';
import { buildQuery, parseQuery } from './utils/query-string';

if (process.env.REACT_APP_BACKEND_BASE_URL) {
    axios.defaults.baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
    axios.defaults.withCredentials = true;
}

const target = document.querySelector('#root');
const store = configureStore();

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://06e04816d44742d09f86ca935e353223@sentry.nextlevelcsgo.com/4',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.05,
        release: process.env.REACT_APP_RELEASE,
    });

    let prevProfileId;
    store.subscribe(() => {
        const state = store.getState();
        const newProfileId = state.app && state.app.profile ? state.app.profile.ID : undefined;

        if (prevProfileId !== newProfileId) {
            if (state.app && state.app.profile && state.app.profile.ID) {
                const profile = state.app.profile;

                Sentry.configureScope((scope) => scope.setUser({ id: profile.ID, username: profile.PersonaName }));
                window.dataLayer.push({
                    event: 'login',
                    userId: profile.ID,
                });

                const customData = {};
                if (profile.Team) {
                    const game = getGameForProfile(profile);

                    window.dataLayer.push({
                        gameKey: game.key,
                    });

                    customData['game_' + game.key] = true;

                    if (profile.Team.NumberOfMatches > 0) {
                        customData.number_of_matches = profile.Team.NumberOfMatches;
                        customData.number_of_sent_offers = profile.Team.NumberOfOffersSent;
                        customData.number_of_received_offers = profile.Team.NumberOfOffersReceived;
                        customData.number_of_requests = profile.Team.NumberOfRequests;
                    }
                    customData.team_id = profile.Team.ID;
                }

                customData['UsedRecorder'] = !!profile.RecorderToken;

                window.Baustein('identify', {
                    name: profile.PersonaName,
                    email: profile.Email,
                    identifier: profile.ID,
                    signature: profile.BausteinSignature,
                    signed_up_at: Math.round(new Date(profile.SignedUpAt).getTime() / 1000),
                    custom: customData,
                });

                if (window.Ybug?.setUser) {
                    window.Ybug.setUser({
                        id: profile.ID,
                        name: profile.PersonaName,
                    });
                }
            } else {
                Sentry.configureScope((scope) => scope.setUser({}));
            }
        }

        prevProfileId = newProfileId;
    });
}

window.addEventListener('unhandledrejection', (e) => {
    handleError(e.reason)(store.dispatch);
});

const AsyncApp = Loadable({
    loader: () => import('./containers/app'),
    loading: Loading,
});

const AsyncStandalonePlayer = Loadable({
    loader: () => import('./containers/recorder/standalone-player'),
    loading: Loading,
});

const AsyncStripePage = Loadable({
    loader: () => import('./containers/billing/StripePaymentPage'),
    loading: Loading,
});

function UserReferral() {
    const { search } = useLocation();

    React.useEffect(() => {
        const params = parseQuery(search);
        if (params.refId) {
            localStorage.setItem('user.refId', params.refId);
            localStorage.setItem('user.refDate', new Date().toISOString());

            delete params.refId;
            browserHistory.replace({
                search: buildQuery(params),
            });
        }

        const userRefId = localStorage.getItem('user.refId');
        if (userRefId) {
            axios.defaults.headers['X-Referral-ID'] = userRefId;
        }

        const userRefDate = localStorage.getItem('user.refDate');
        if (userRefDate) {
            axios.defaults.headers['X-Referral-Date'] = userRefDate;
        }
    }, [search]);

    return null;
}

function RootApp() {
    const profile = useSelector((state) => state.app.profile);
    const mode = profile?.Settings?.Theme || 'dark';
    const [resolvedAutoMode, setResolvedAutoMode] = React.useState('dark');
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (mode !== 'auto') {
            return;
        }

        if (!window.matchMedia) {
            return;
        }

        setResolvedAutoMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');

        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
            const newColorScheme = e.matches ? 'dark' : 'light';
            setResolvedAutoMode(newColorScheme);
        });
    }, [mode]);

    React.useEffect(() => {
        async function load() {
            try {
                const res = await axios.get('/api/boosts/features');
                setBoostFeatures(res.data)(dispatch);
            } catch (e) {
                setTimeout(load, 5000);
            }
        }
        load();
    }, []);

    React.useEffect(() => {
        if (!profile?.Team) {
            return;
        }

        async function load() {
            try {
                const resGiveAway = await axios.get('/api/giveaways/active');
                setGiveAways(resGiveAway.data)(dispatch);
            } catch (e) {
                setTimeout(load, 5000);
            }
        }
        load();
    }, [!!profile?.Team]);

    /*
    const theme = React.useMemo(() => {
        let usedMode = mode;
        if (usedMode === 'auto') {
            usedMode = resolvedAutoMode;
        }

        return usedMode === 'light' ? lightTheme : darkTheme;
    }, [mode, resolvedAutoMode]);
     */
    const theme = darkTheme;

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <UserReferral />
            <AdContextProvider>
                <Switch>
                    <Route path="/recorder/clips/:uid/player" exact component={AsyncStandalonePlayer} />
                    <Route path="/stripe" exact component={AsyncStripePage} />
                    <Route component={AsyncApp} />
                </Switch>
            </AdContextProvider>
        </ThemeProvider>
    );
}

render(
    <Provider store={store}>
        <Router history={browserHistory}>
            <StyledEngineProvider injectFirst>
                <RootApp />
            </StyledEngineProvider>
        </Router>
    </Provider>,
    target
);
unregister();
