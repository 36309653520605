import React from 'react';
import axios from 'axios';
import Match from './match';
import { CircularProgress, Paper } from '@mui/material';
import { Redirect, useParams } from 'react-router-dom';
import { handleError } from '../../components/snackbar/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useAds } from '../../components/ads/AdContextProvider';
import { Profile } from '../../model/profile';
import { GAME_ID_CSGO } from '../../utils/games';
import CustomTabs from 'containers/components/CustomTabs';
import SearchIcon from '@mui/icons-material/Search';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import GroupWorkRoundedIcon from '@mui/icons-material/GroupWorkRounded';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

function StandaloneMatch() {
    const [isLoading, setLoading] = React.useState(true);
    const [match, setMatch] = React.useState(null);
    const { id } = useParams();
    const dispatch = useDispatch();
    const { profile } = useSelector((state) => state.app);
    const { adsEnabled } = useAds();

    const tabs = [
        { label: 'SEARCH', icon: <SearchIcon className="icon" />, path: '/search' },
        { label: 'GROUPS', icon: <GroupWorkRoundedIcon className="icon" />, path: '/groups' },

        { label: 'MATCHES', icon: <CalendarTodayIcon className="icon" />, path: '/matches' },
        { label: 'STATS', icon: <TrendingUpIcon className="icon" />, path: '/stats' },
    ];

    React.useEffect(() => {
        (async () => {
            try {
                if (!id) {
                    return;
                }

                const res = await axios.get('/api/matches/' + id, {
                    headers: {
                        Accept: 'application/vnd.pracc.v1+json',
                    },
                });

                setMatch(res.data);
            } catch (e) {
                handleError(e)(dispatch);
            } finally {
                setLoading(false);
            }
        })();
    }, [id]);

    if (isLoading) {
        return <CircularProgress />;
    }
    if (!match) {
        return <Redirect to="/matches" />;
    }

    //refresh match so long IP is empty
    setTimeout(() => {
        if (match && profile.Team.GameID === GAME_ID_CSGO && match.GameServer.IP === '') {
            (async () => {
                try {
                    const res = await axios.get('/api/matches/' + id, {
                        headers: {
                            Accept: 'application/vnd.pracc.v1+json',
                        },
                    });

                    setMatch(res.data);
                } catch (e) {
                    handleError(e)(dispatch);
                }
            })();
        }
    }, 5000);
    return (
        <>
            <CustomTabs tabs={tabs} value={2} handleChange={() => {}} />

            <Paper
                style={{
                    padding: '20px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '80px',
                    '@media (max-width: 1024px)': {
                        marginTop: 2,
                    },
                }}
            >
                <Match match={match} isModal={false} />
            </Paper>
        </>
    );
}

export default StandaloneMatch;
