import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
    componentDidMount() {
        this.scrollToTop();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.scrollToTop();
        }
    }

    scrollToTop() {
        window.scrollTo(0, 0);
    }

    render() {
        return null;
    }
}

export default withRouter(ScrollToTop);
