import React from 'react';
import { Box, Button } from '@mui/material';
import StripePaymentForm from './StripePaymentForm';
import { useLocation } from 'react-router-dom';
import { parseQuery } from '../../utils/query-string';
import PraccPaper from '../../components/paper/PraccPaper';
import PraccPaperHeader from '../../components/paper/PraccPaperHeader';
import logo from '../app/img/betalogo.png';
import styled from 'styled-components';
// @ts-ignore
import praccNameFont from '../../assets/ethnocentric.ttf';
import DisableAds from '../../components/ads/DisableAds';

export const PraccName = styled('div')`
    @font-face {
        font-family: 'LogoFont';
        font-style: normal;
        font-weight: normal;
        src: url(${praccNameFont});
    }

    &:hover {
        color: white;
    }

    user-select: none;
    z-index: 15;
    font-family: 'LogoFont';
    color: white;
    font-size: xx-large;
    transform: scale(0.8);
    padding-top: 3px;

    display: flex;
    align-items: center;
`;

function StripePaymentPage() {
    const location = useLocation();
    const queryParams = React.useMemo(() => parseQuery(location.search), [location.search]);

    React.useEffect(() => {
        if ((window as any).Baustein) {
            (window as any).Baustein('setMessengerToggleHidden', true);
        }
    }, []);

    return (
        <>
            <DisableAds />
            <Box
                sx={{
                    backgroundColor: '#0d47a1',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 1,
                }}
            >
                <img height={50} src={logo} alt="PRACC Logo" />
                <PraccName>PRACC</PraccName>
            </Box>
            <PraccPaper sx={{ margin: 2 }}>
                <PraccPaperHeader>Choose Payment Method</PraccPaperHeader>
                <StripePaymentForm clientSecret={queryParams.clientSecret as string} />
                <Button
                    onClick={() => {
                        window.opener?.postMessage('is-closed', '*');
                        window.close();
                    }}
                    fullWidth
                    sx={{ marginTop: 0.5 }}
                >
                    Cancel
                </Button>
            </PraccPaper>
        </>
    );
}

export default StripePaymentPage;
