import { Alert, Container, Paper } from '@mui/material';
import React from 'react';
import { Header } from '../../components/Layout';
import { makeStyles } from '@mui/styles';
import PraccPaperHeader from '../../components/paper/PraccPaperHeader';
import PraccPaper from '../../components/paper/PraccPaper';

const useStyles = makeStyles((theme) => ({
    header: {
        fontWeight: '400!important',
        borderBottom: '1px solid #eee',
        marginTop: '30px',
    },
    hr: {
        border: 0,
        borderBottom: '1px solid #fff',
        borderTop: '1px solid #eee',
        margin: '20px 0',
    },
    well: {
        backgroundColor: '#f5f5f5',
        border: '1px solid #e3e3e3',
        marginBottom: '20px',
        minHeight: '20px',
        padding: '19px',
        boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 5%)',
    },
    textCenter: {
        textAlign: 'center',
    },
}));

export default function Security() {
    const classes = useStyles();
    return (
        <PraccPaper>
            <PraccPaperHeader>Platform Security</PraccPaperHeader>
            <h3 className={classes.header}>Report a Security Issue</h3>
            <p>
                If you need to report a security issue, please email{' '}
                <a href="mailto:admin@pracc.com">admin@pracc.com</a>. In the email, please include details about the
                issue such as a proof of concept and potentially proposed mitigation steps.
            </p>
            <h3 className={classes.header}>Data Center Security</h3>
            <ul>
                <li>All of our servers are hosted in ISO 27001 certified data centers.</li>
                <li>
                    Data centers are video-monitored with a high-security perimeter surrounding the entire data center
                    park.
                </li>
                <li>
                    Entry is only possible via electronic access control terminals with a transponder key or admission
                    card.
                </li>
                <li>
                    All movements are recorded and documented. Ultra-modern surveillance cameras provide 24/7 monitoring
                    of all access routes, entrances, security door interlocking systems and server rooms.
                </li>
            </ul>
            <h3 className={classes.header}>Network Communications</h3>
            <ul>
                <li>All connections between PRACC.COM and you are forced over TLS encrypted connections.</li>
                <li>
                    Clients are instructed via appropriate HTTP strict transport security (HSTS) headers to only
                    establish encrypted connections.
                </li>
                <li>All pushing and pulling of source code is done either over HTTPS or SSH connections.</li>
            </ul>
            <h3 className={classes.header}>System Security</h3>
            <ul>
                <li>All servers run on Linux with the latest available security updates.</li>
                <li>
                    Automated update procedures are in place to quickly react to any newly released security updates.
                </li>
                <li>
                    Distributed Denial of Service (DDoS) mitigation services are in place powered by industry-leading
                    solutions.
                </li>
            </ul>
            <h3 className={classes.header}>Software Security</h3>
            <ul>
                <li>Our source code is continuously reviewed using Scrutinizer.</li>
                <li>Additionally, all source code changes go through a manual review process before being merged.</li>
            </ul>
            <p>
                All data on employees' systems is fully encrypted including the operating system. As such, all data is
                unreadable to anyone but those with proper decryption keys should a device be lost or stolen.
            </p>
            <h3 className={classes.header}>Payment Data Security</h3>
            <ul>
                <li>All payment data like credit card data is directly stored by PCI-compliant payment providers.</li>
                <li>No payment data reaches or is stored on our servers.</li>
            </ul>
            <hr className={classes.hr} />
            <Alert severity="info">
                If you have any questions, concerns or feedback about our platform security, please do not hesitate to{' '}
                <a href="mailto:support@pracc.com">contact our support</a>.
            </Alert>
        </PraccPaper>
    );
}
