import React from 'react';
import Button from '@mui/material/Button';
import Icon from './icon';
import { PraccDialog } from './layout/dialogs';
import {
    ButtonGroup,
    ButtonProps,
    capitalize,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import ButtonWithAsyncAction from './ButtonWithAsyncAction';
import { handleError } from './snackbar/reducer';
import { useDispatch } from 'react-redux';

interface DeleteButtonProps extends ButtonProps {
    targetLabel: React.ReactNode;
    onDelete: () => Promise<void>;
    buttonText?: React.ReactNode;
    verb?: string;
}

function DeleteButton({ targetLabel, onDelete, children, verb = 'delete', ...rest }: DeleteButtonProps) {
    const [open, setOpen] = React.useState(false);
    const [isDeleting, setDeleting] = React.useState(false);
    const dispatch = useDispatch();

    return (
        <>
            <ButtonWithAsyncAction
                {...rest}
                variant="contained"
                onClick={() => setOpen(true)}
                color="secondary"
                disabled={isDeleting}
                startIcon={<Icon>delete_forever</Icon>}
            >
                {children}
            </ButtonWithAsyncAction>
            <PraccDialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>
                    {capitalize(verb)} {targetLabel}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you really want to {verb} your {targetLabel}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup variant={'contained'}>
                        <Button
                            color={'secondary'}
                            onClick={async () => {
                                setDeleting(true);
                                try {
                                    await onDelete();
                                    setOpen(false);
                                } catch (e) {
                                    handleError(e)(dispatch);
                                }
                                setDeleting(false);
                            }}
                        >
                            Yes
                        </Button>
                        <Button color={'primary'} onClick={() => setOpen(false)}>
                            No, close
                        </Button>
                    </ButtonGroup>
                </DialogActions>
            </PraccDialog>
        </>
    );
}

export default DeleteButton;
