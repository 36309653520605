import axios from 'axios';
import { first, firstValueFrom } from 'rxjs';
import WebsocketManager from '../../utils/websocket-manager';
import { AccountProvider } from '../../model/profile';

interface AuthEvent {
    Payload: {
        VerifyUrl: string;
    };
}

export function getProviderDisplayName(provider: AccountProvider): string {
    if (provider === 'battlenet') {
        return 'Battle.Net';
    }

    return provider.slice(0, 1).toUpperCase() + provider.slice(1);
}

export function openAuthWindow(authUrl: string): WindowProxy | null {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width;
    const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const w = 300;
    const h = 500;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const newWindow = window.open(
        authUrl,
        'Authentication Window',
        `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    );

    newWindow?.focus();

    return newWindow;
}

export async function retrieveAuthUrl(provider: string, authUrlParams: Record<string, string>) {
    let authPath = `/api/auth/${provider}`;
    if (provider === 'riot') {
        authPath = '/api/riot-sign-on';
    }

    const authUrlRes = await axios.get(authPath, {
        params: authUrlParams,
    });

    return authUrlRes.data.AuthUrl as string;
}

export async function retrieveAuthEvent(websocket: WebsocketManager, authUrl: string): Promise<AuthEvent> {
    const authWindow = openAuthWindow(authUrl);

    const authEvent = (await Promise.race([
        new Promise((resolve, reject) => {
            try {
                if (authWindow) {
                    authWindow.addEventListener('message', (evt) => {
                        resolve(JSON.parse(evt.data));
                    });
                    authWindow.addEventListener('close', () => {
                        reject(new Error('The authentication window was closed.'));
                    });
                }
            } catch (e) {
                console.error(`Failed to listen to auth window: ${e}`);
            }
        }),
        firstValueFrom(
            websocket.emitter.pipe(
                first((e) => {
                    return e.MessageType === 'auth.verify';
                })
            )
        ),
    ])) as AuthEvent;

    if (!authWindow?.closed) {
        authWindow?.close();
    }

    return authEvent;
}
