import axios from 'axios';
import moment from 'moment-timezone';
import { QUEUE_MESSAGE } from 'components/snackbar/reducer';
import { handleError } from '../../components/snackbar/reducer';
import WebsocketManager from '../../utils/websocket-manager';
import { v4 as uuidv4 } from 'uuid';
import SideMenu from './SideMenu';
import games, { GAME_ID_CSGO } from '../../utils/games';

export const LOAD_PROFILE_REQUESTED = 'app/LOAD_PROFILE_REQUESTED';
export const LOAD_PROFILE = 'app/LOAD_PROFILE';
export const SET_BOOST_FEATURES = 'app/SET_BOOST_FEATURES';
export const SET_GIVE_AWAYS = 'app/SET_GIVE_AWAYS';
export const LOAD_NOTIFICATIONS = 'app/LOAD_NOTIFICATIONS';
export const PATCH_PROFILE = 'app/PATCH_PROFILE';
export const SET_EMAIL_RESENDING_STATUS = 'app/EMAIL_RESENDING_STATUS';
export const SET_HIDE_EMAIL_CONFIRMATION_ALERT = 'app/SET_HIDE_EMAIL_CONFIRMATION_ALERT';
export const SET_HIDE_RATING_IS_OPEN_ALERT = 'app/SET_HIDE_RATIN_IS_OPEN_ALERT';
export const SAVE_EMAIL_REQUESTED = 'app/SAVE_EMAIL_REQUESTED';
export const OPEN_MSGS = 'app/OPEN_MSGS';
export const ADD_NOTIFICATION = 'app/ADD_NOTIFICATION';
export const SET_UNREAD_MSGS = 'app/SET_UNREAD_MSGS';
export const UPDATE_TEAM_PROFILE = 'app/UPDATE_TEAM_PROFILE';
export const SELECT_TEAM = 'app/SELECT_TEAM';
export const REMOVE_TEAM_PROFILE = 'app/REMOVE_TEAM';
export const OPEN_CHAT = 'app/OPEN_CHAT';
export const SET_ATTACHED_CHAT_OBJECTS = 'app/SET_ATTACHED_CHAT_OBJECTS';
export const UPDATE_BOOST_DETAILS = 'app/UPDATE_BOOST_DETAILS';
export const UPDATE_RATING_DETAILS = 'app/UPDATE_RATING_DETAILS';
export const SET_ACTIVE_CHATS = 'app/SET_ACTIVE_CHATS';
export const SELECT_GAME = 'app/SELECT_GAME';

function getWebsocketEndpoint() {
    if (process.env.REACT_APP_BACKEND_BASE_URL) {
        const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
        const wsProtocol = baseUrl.indexOf('http://') === 0 ? 'ws://' : 'wss://';

        return wsProtocol + baseUrl.split('://', 2)[1] + '/api/ws';
    }

    const wsProtocol = window.location.protocol === 'http:' ? 'ws' : 'wss';
    let host = window.location.host;
    if (host === 'localhost:3000') {
        host = 'localhost:4333';
    }

    return wsProtocol + '://' + host + '/api/ws';
}

const instanceId = uuidv4();
axios.defaults.headers['X-Client-Instance'] = instanceId;

const initialState = {
    isLoading: true,
    isLoadingNotifications: true,
    isResendingConfirmationEmail: false,
    isEmailUnconfirmedAlertHidden: false,
    profile: null,
    notifications: [],
    unreadMsgs: 0,
    msgDrawerOpen: false,
    websocket: new WebsocketManager(getWebsocketEndpoint(), instanceId),
    selectedChatId: null,
    attachedChatMessageObjects: {},
    isRatingOpenAlertHidden: false,
    instanceId,
    boostFeatures: {},
    giveAways: [],
    boostFeatureDetails: {
        open: false,
        entityType: '',
        featureId: '',
    },
    ratingDetailsDialog: {
        open: false,
        teamId: 0,
    },
    activeChats: [],
};

let notificationCounter = 0;

const STORAGE_ACTIVE_TEAM_ID = 'app.active_team_id';
function updateActiveTeam(newTeamId) {
    if (newTeamId) {
        axios.defaults.headers['X-Selected-Team-ID'] = newTeamId;
        localStorage.setItem(STORAGE_ACTIVE_TEAM_ID, newTeamId);
    } else {
        delete axios.defaults.headers['X-Selected-Team-ID'];
    }
}

function selectActiveTeam(newProfile, prevProfile) {
    if (newProfile.Teams.length <= 0) {
        return null;
    }

    if (prevProfile && prevProfile.Team) {
        const team = newProfile.Teams.find((team) => team.ID === prevProfile.Team.ID);
        if (team) {
            return team;
        }
    }

    const storedTeamId = localStorage.getItem(STORAGE_ACTIVE_TEAM_ID);
    if (storedTeamId) {
        const storedTeamIdNumber = Number(storedTeamId);
        const team = newProfile.Teams.find((team) => team.ID === storedTeamIdNumber);
        if (team) {
            return team;
        }
    }

    return newProfile.Teams[0];
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_BOOST_FEATURES:
            return {
                ...state,
                boostFeatures: action.features,
            };
        case SET_GIVE_AWAYS:
            return {
                ...state,
                giveAways: action.giveAways,
            };
        case LOAD_PROFILE_REQUESTED:
            return {
                ...state,
                isLoading: true,
            };

        case PATCH_PROFILE:
            return {
                ...state,
                profile: {
                    ...(state.profile || {}),
                    ...action.profile,
                },
            };

        case LOAD_PROFILE:
            const profile = action.payload;
            if (profile) {
                if (profile.Settings) {
                    if (!profile.Settings.LocalTimezone) {
                        profile.Settings.LocalTimezone = moment.tz.guess();
                    }
                    if (!profile.Settings.Theme) {
                        profile.Settings.Theme = 'dark';
                    }
                }

                profile.Team = selectActiveTeam(profile, state.profile);
                profile.GameId = GAME_ID_CSGO;

                if (profile.Team) {
                    updateActiveTeam(profile.Team.ID);
                    profile.GameId = profile.Team.GameID;
                } else {
                    updateActiveTeam(null);
                }
            } else {
                updateActiveTeam(null);
            }

            return {
                ...state,
                isLoading: false,
                profile: profile,
            };

        case SELECT_TEAM:
            const team = state.profile.Teams.find((team) => team.ID === action.teamId);
            if (!team) {
                return state;
            }

            updateActiveTeam(team.ID);

            return {
                ...state,
                profile: {
                    ...state.profile,
                    Team: team,
                    GameId: team.GameID,
                },
            };

        case SELECT_GAME:
            const game = games.find((game) => game.id === action.gameId);
            if (!game) {
                return state;
            }

            return {
                ...state,
                profile: {
                    ...state.profile,
                    GameId: action.gameId,
                },
            };

        case REMOVE_TEAM_PROFILE:
            const teamIndex = state.profile.Teams.findIndex((team) => team.ID === action.teamId);
            if (teamIndex < 0) {
                return state;
            }

            const newProfile = {
                ...state.profile,
                Teams: state.profile.Teams.filter((team) => team.ID !== action.teamId),
            };

            if (newProfile.Team && newProfile.Team.ID === action.teamId) {
                newProfile.Team = selectActiveTeam(newProfile, state.profile);

                if (newProfile.Team) {
                    updateActiveTeam(newProfile.Team.ID);
                } else {
                    updateActiveTeam(null);
                }
            }

            return {
                ...state,
                profile: newProfile,
            };

        case UPDATE_TEAM_PROFILE:
            const newTeams = [...state.profile.Teams];
            const index = newTeams.findIndex((team) => team.ID === action.payload.ID);
            if (index >= 0) {
                newTeams[index] = action.payload;
            } else {
                newTeams.push(action.payload);
            }

            let selectedTeam = state.profile.Team;
            if (selectedTeam === null || selectedTeam.ID === action.payload.ID) {
                selectedTeam = action.payload;
            }

            return {
                ...state,
                profile: {
                    ...state.profile,
                    Team: selectedTeam,
                    Teams: newTeams,
                },
            };

        case SET_EMAIL_RESENDING_STATUS:
            return {
                ...state,
                isResendingConfirmationEmail: action.sending,
            };

        case SET_HIDE_EMAIL_CONFIRMATION_ALERT:
            return {
                ...state,
                isEmailUnconfirmedAlertHidden: action.hidden,
            };
        case SET_HIDE_RATING_IS_OPEN_ALERT:
            return {
                ...state,
                isRatingOpenAlertHidden: action.hidden,
            };
        case ADD_NOTIFICATION:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        ...action.noti,
                        id: notificationCounter++,
                    },
                ].sort(function (a, b) {
                    return new Date(b.CreatedAt) - new Date(a.CreatedAt);
                }),
                unreadMsgs: action.newMsg ? state.unreadMsgs + 1 : state.unreadMsgs,
            };
        case OPEN_MSGS:
            return {
                ...state,
                msgDrawerOpen: action.stat,
            };
        case SET_UNREAD_MSGS:
            return {
                ...state,
                unreadMsgs: action.numb,
            };
        case LOAD_NOTIFICATIONS:
            return {
                ...state,
                isLoadingNotifications: false,
            };
        case OPEN_CHAT:
            return {
                ...state,
                selectedChatId: action.chatId,
            };

        case SET_ATTACHED_CHAT_OBJECTS:
            return {
                ...state,
                attachedChatMessageObjects: action.objects,
            };
        case SET_ACTIVE_CHATS:
            return {
                ...state,
                activeChats: action.data,
            };

        case UPDATE_BOOST_DETAILS:
            return {
                ...state,
                boostFeatureDetails: {
                    ...state.boostFeatureDetails,
                    ...action.props,
                },
            };

        case UPDATE_RATING_DETAILS:
            return {
                ...state,
                ratingDetailsDialog: {
                    ...state.ratingDetailsDialog,
                    ...action.props,
                },
            };

        default:
            return state;
    }
};

export function showRatingDetails(teamId) {
    return (dispatch) =>
        dispatch({
            type: UPDATE_RATING_DETAILS,
            props: {
                open: true,
                teamId,
            },
        });
}

export function hideRatingDetails() {
    return (dispatch) =>
        dispatch({
            type: UPDATE_RATING_DETAILS,
            props: {
                open: false,
            },
        });
}

export function showGlobalBoostFeatureDetails(entityType, featureId) {
    return (dispatch) =>
        dispatch({
            type: UPDATE_BOOST_DETAILS,
            props: {
                open: true,
                entityType,
                featureId,
            },
        });
}

export function hideGlobalBoostFeatureDetails() {
    return (dispatch) =>
        dispatch({
            type: UPDATE_BOOST_DETAILS,
            props: {
                open: false,
            },
        });
}

export function patchProfile(profile) {
    return (dispatch) => dispatch({ type: PATCH_PROFILE, profile });
}

export const setAttachedChatObjects = (objects) => {
    return (dispatch) =>
        dispatch({
            type: SET_ATTACHED_CHAT_OBJECTS,
            objects,
        });
};

export const openChat = (chatId, objects = {}) => {
    return async (dispatch) => {
        dispatch({
            type: OPEN_CHAT,
            chatId,
        });
        dispatch({
            type: SET_ATTACHED_CHAT_OBJECTS,
            objects,
        });
    };
};

export const resendConfirmationEmail = () => {
    return async (dispatch) => {
        dispatch({
            type: SET_EMAIL_RESENDING_STATUS,
            sending: true,
        });

        try {
            await axios.post('/api/profile/resend-confirmation-email');

            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Confirmation email was resent!',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        } finally {
            dispatch({
                type: SET_EMAIL_RESENDING_STATUS,
                sending: false,
            });
        }
    };
};

export const setEmailUnconfirmedHidden = (hide) => {
    return (dispatch) => {
        dispatch({
            type: SET_HIDE_EMAIL_CONFIRMATION_ALERT,
            hidden: hide,
        });
    };
};

export const setRatingIsOpenHidden = (hide) => {
    return (dispatch) => {
        dispatch({
            type: SET_HIDE_RATING_IS_OPEN_ALERT,
            hidden: hide,
        });
    };
};

export const updateProfile = (newProfileData) => {
    return (dispatch) => {
        dispatch({
            type: LOAD_PROFILE,
            payload: newProfileData,
        });
    };
};

export const updateTeamProfile = (newTeamProfileData) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_TEAM_PROFILE,
            payload: newTeamProfileData,
        });
    };
};

export const selectTeam = (teamId) => {
    return (dispatch) =>
        dispatch({
            type: SELECT_TEAM,
            teamId,
        });
};

export const selectGame = (gameId) => {
    return (dispatch) =>
        dispatch({
            type: SELECT_GAME,
            gameId,
        });
};

export const setBoostFeatures = (features) => {
    return async (dispatch) => {
        dispatch({
            type: SET_BOOST_FEATURES,
            features,
        });
    };
};

export const setGiveAways = (giveAways) => {
    return async (dispatch) => {
        dispatch({
            type: SET_GIVE_AWAYS,
            giveAways,
        });
    };
};

export const loadProfile = () => {
    return async (dispatch) => {
        dispatch({
            type: LOAD_PROFILE_REQUESTED,
        });

        try {
            const res = await axios.get('/api/profile');
            dispatch({
                type: LOAD_PROFILE,
                payload: res.data,
            });
        } catch (e) {
            if (e.response && e.response.status === 401) {
                dispatch({
                    type: LOAD_PROFILE,
                    payload: null,
                });

                return;
            }

            handleError(e)(dispatch);
        }
    };
};

export const openMsgs = (stat) => {
    return async (dispatch) => {
        dispatch({
            type: OPEN_MSGS,
            stat,
        });
        dispatch({
            type: SET_UNREAD_MSGS,
            numb: 0,
        });

        try {
            const res = await axios.get('/api/notifications/read');
        } catch (e) {
            // handleError(e)(dispatch);
        }
    };
};

export const showNotifySnack = (text) => {
    return (dispatch) => {
        dispatch({
            type: QUEUE_MESSAGE,
            msg: text,
            variant: 'info',
        });
    };
};

export const addNotification = (noti, newMsg) => {
    return (dispatch) => {
        dispatch({
            type: LOAD_NOTIFICATIONS,
        });
        dispatch({
            type: ADD_NOTIFICATION,
            noti,
            newMsg,
        });
    };
};

export const setUnreadMsgs = (numb) => {
    return (dispatch) => {
        dispatch({
            type: SET_UNREAD_MSGS,
            numb,
        });
    };
};

export const setActiveChats = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_ACTIVE_CHATS,
            data,
        });
    };
};

export const updateIsLoadingNotifications = () => {
    return (dispatch) => {
        dispatch({
            type: LOAD_NOTIFICATIONS,
        });
    };
};
