import React, { Component } from 'react';
import styled from 'styled-components';
import LoginFtp from './img/login-ftp.png';
import FtpAufbau from './img/ftp-aufbau.jpg';
import TeamConfiguration from './img/team-configuration.png';
import { Button, Paper, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PraccPaper from '../../components/paper/PraccPaper';
import PraccPaperHeader from '../../components/paper/PraccPaperHeader';

const ScreenShot = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-top: 20px;
    margin-bot: 20px;
`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

function getSteps() {
    return ['Install FTP-Client', 'Upload Plugin', 'Configure Team-Page', 'Add admins to the Bot'];
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return <InstallFtpStep />;
        case 1:
            return <SelectUploadPlugin />;
        case 2:
            return <TeamConfigurationStep />;
        case 3:
            return <AdminStep />;
        default:
            return 'Unknown stepIndex';
    }
}

class PluginPage extends Component {
    render = () => {
        return (
            <div style={{ width: '100%' }}>
                <PraccPaper>
                    <PraccPaperHeader>PRACC.COM Plugin Overview</PraccPaperHeader>

                    <PluginStepper />
                </PraccPaper>
            </div>
        );
    };
}

function PluginStepper() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                            <Typography>{getStepContent(index)}</Typography>
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                    >
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleReset} className={classes.button}>
                        Reset
                    </Button>
                </Paper>
            )}
        </div>
    );
}

function InstallFtpStep() {
    return (
        <div>
            Download an FTP Client (for example: <a href="https://winscp.net/eng/download.php">WinSCP</a>) and login
            with the credentials provided from your gameserver hoster.
            <ScreenShot src={LoginFtp} width={'50%'} length={'50%'} />
        </div>
    );
}

function SelectUploadPlugin() {
    return (
        <div>
            <p>
                If you don't know what operating system your server is running on, you can easily find out.
                <br /> Connect to your server and type in the console "status", in the output you will find an entry
                "os" if it says Linux, download the Linux version if not then the Windows version.
            </p>
            <br />
            <h3>Sourcemod-Versions</h3>
            <ul>
                <li>
                    <a href="https://cdn.pracc.com/plugin-sm-linux.zip">
                        Download the sourcemod Plugin for linux gameserver
                    </a>
                </li>
                <li>
                    <a href="https://cdn.pracc.com/plugin-sm-windows.zip">
                        Download the sourcemod Plugin for windows gameserver{' '}
                    </a>
                </li>
            </ul>
            <p>
                If you have been using the standalone version, we recommend that you delete it, as we no longer support
                this version.
                <br />
                After you have downloaded the correct version, you need to unzip the ZIP file and upload the contents to
                your server.
            </p>
            <ScreenShot src={FtpAufbau} width={'50%'} length={'50%'} />
            <p>
                After you have uploaded the files, you have to restart your server once. <br />
                If you now connect to your server and run the following commands using RCON from the console, the
                following output should appear. The versions may differ. It is only important that the corresponding
                plugins are listed.
                <br />
                <br />
                <b>plugin_print:</b>
                <br />
                Loaded plugins:
                <br />
                ---------------------
                <br />
                0: "Metamod:Source 1.11.0-dev+1148"
                <br />
                ---------------------
                <br />
                <br />
                <b>sm plugins list</b>
                <br />
                [SM] Listing 3 plugins:
                <br />
                1 "Updater" (1.3.4) by GoD-Tony, Tk /id/Teamkiller324
                <br />
                2 "PRACC.COM Sourcemod" (0.3.27) by PRACC.COM
                <br />
                3 "PRACC.COM Warmup" (0.6.1) by PRACC.COM
                <br />
            </p>
        </div>
    );
}

function TeamConfigurationStep() {
    return (
        <div>
            <p>
                In order to use the bot properly, settings still need to be made in the team (<a href={'/team'}>Team</a>
                ).
                <br />
                In the Server tab you have to enter the IP address of your server, for example 123.456.789.111:27015,
                and the password to join the server.
                <br />
                Finally you have to enter your RCON password so that the bot can execute all necessary commands.
            </p>
            <ScreenShot src={TeamConfiguration} />
            <p>
                After you have entered the information, you can start the bot with the (RE-)START.
                <br />
                When the bot is successfully started, a corresponding message appears.
            </p>
        </div>
    );
}

function AdminStep() {
    return (
        <div>
            <p>
                To use the bot, your PRACC.COM account must be connected to your Steam account. (
                <a href={'/settings'}>Link Steam-Account</a>)<br />
                In order for everyone to use the commands from the team everyone must have their Steam account linked.
                <br />
                When a new team member joins the team you must restart the bot, this also applies when someone leaves
                the team.
            </p>
        </div>
    );
}

export default PluginPage;
