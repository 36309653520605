import React from 'react';
import { connect } from 'react-redux';
import { Divider, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { bindActionCreators } from 'redux';
import { selectGame } from '../reducer';
import { browserHistory } from '../../../store';
import games from '../../../utils/games';
import { IoIosArrowForward } from 'react-icons/io';

class GameSelectionButton extends React.Component {
    state = {
        anchorEl: null,
        open: false,
    };

    render() {
        const { profile } = this.props;
        const game = games.find((g) => g.id === profile.GameId);

        return (
            <React.Fragment>
                <div
                    onClick={(event) => {
                        this.setState({
                            anchorEl: event.currentTarget,
                            open: true,
                        });
                    }}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        cursor: 'pointer',
                        position: 'relative',
                    }}
                >
                    <div
                        style={{
                            width: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Avatar src={game.logo} sx={{ width: 24, height: 24, marginRight: '0.5rem' }} />
                        <Typography noWrap sx={{ color: 'white', fontSize: '13px', fontWeight: 700, width: '7rem' }}>{game.label}</Typography>
                    </div>

                    <IoIosArrowForward color="white" size={20} />
                </div>
                <Menu
                    anchorEl={this.state.anchorEl}
                    open={this.state.open}
                    onClose={() => {
                        this.handleClose();
                    }}
                    PaperProps={{
                        sx: {
                            overflow: 'visible',
                            mt: '-2.5rem',
                            ml: '13rem',
                            backgroundColor: 'rgb(12, 17, 38)',
                            backgroundImage: 'radial-gradient(ellipse farthest-side, rgba(0, 111, 255, 0.08), transparent)',
                            backgroundSize: '100%',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            border: '2px solid #181D31',
                            boxShadow: 3,
                            borderRadius: '10px',
                            top: '13rem',
                            '& .MuiAvatar-root': {
                                width: 20,
                                height: 20,
                            },
                            '@media (max-width: 600px)': {
                                mt: '-2.5rem',
                                ml: '11rem',
                            },

                            '@media (max-width: 1024px)': {
                                maxHeight: '80vh',
                                overflow: 'auto',
                            },
                        },
                    }}
                >
                    {games.map((game) => (
                        <MenuItem
                            key={game.key}
                            onClick={() => {
                                this.selectGame(game.id);
                            }}
                        >
                            <div style={{
                                aspectRatio: '1/1',
                                margin: '0 auto',
                                maxWidth: '24px',
                                borderRadius: '4px',
                                overflow: 'hidden',
                                marginRight: '8px'
                            }}>
                                <img style={{
                                    width: '100%',
                                    height: '100%',
                                }} src={game.logo} />
                            </div>
                            <ListItemText>{game.label}</ListItemText>
                        </MenuItem>
                    ))}
                </Menu>
            </React.Fragment>
        );
    }

    selectGame(gameId) {
        const { profile } = this.props;
        if (profile.GameId !== gameId) {
            this.props.selectGame(gameId);
        }
        this.handleClose();
        if (this.props.isMenu) {
            this.props.closeMenuPaper();
        }
    }

    handleClose() {
        this.setState({
            anchorEl: null,
            open: false,
        });
    }
}

const Connected = connect(
    (state) => ({
        profile: state.app.profile,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                selectGame,
            },
            dispatch
        )
)(GameSelectionButton);

export default Connected;
