import React from 'react';
import Link from '@mui/material/Link';
import { Modal } from '@mui/material';

interface GrenadeFinderVideoLinkProps {
    label?: React.ReactNode;
}

interface GrenadeFinderModalProps {
    open: boolean;
    onClose: () => void;
}

export function GrenadeFinderModal({ open, onClose }: GrenadeFinderModalProps) {
    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: '15vh' }}
        >
            <div style={{ position: 'relative', width: `${16 * 3.1}vw`, height: `${9 * 3.1}vw` }}>
                <iframe
                    src="https://customer-b7e4ar7kdo3ievga.cloudflarestream.com/6ae6e875e055e3b1a7656ed5ce29aebe/iframe?autoplay=true&poster=https%3A%2F%2Fcustomer-b7e4ar7kdo3ievga.cloudflarestream.com%2F6ae6e875e055e3b1a7656ed5ce29aebe%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
                    loading="lazy"
                    style={{ border: 'none', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}
                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                    allowFullScreen
                />
            </div>
        </Modal>
    );
}

function GrenadeFinderVideoLink({ label }: GrenadeFinderVideoLinkProps) {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Link
                onClick={(e) => {
                    setOpen(true);
                    e.preventDefault();
                }}
                sx={{ cursor: 'pointer' }}
                underline="hover"
            >
                {label || 'Grenade Finder'}
            </Link>
            <GrenadeFinderModal open={open} onClose={() => setOpen(false)} />
        </>
    );
}

export default GrenadeFinderVideoLink;
