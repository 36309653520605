import React from 'react';
import { Plan, Product } from '../../model/products';
import clsx from 'clsx';
import { Button, Divider, emphasize, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Subscription } from '../../model/subscription';
import { ProfileTeam } from '../../model/profile';
import { computePlanMonthlyAmount, getTrial, isPlanActive, isTrialActive } from './utils';
import PlanAmount from './plan-amount';
import { yellow } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
    plan: {
        position: 'relative',
        padding: theme.spacing(2),
        border: `2px solid ${emphasize(theme.palette.background.paper, 0.5)}`,
        marginRight: theme.spacing(2),
        overflow: 'hidden',
        minWidth: '10vw',
        flexBasis: 1,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '&:last-child': {
            marginRight: 0,
        },
        '&.active': {
            border: `2px solid ${theme.palette.warning.dark}`,
        },
        '&& .special': {
            position: 'absolute',
            top: 10,
            left: -40,
            fontSize: 12,
            transform: 'rotate(-25deg)',
            backgroundColor: yellow[800],
            padding: theme.spacing(0.5),
            width: 180,
            textAlign: 'center',
            boxShadow: theme.shadows[2],
        },
    },

    planDivider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    planItemGroup: {
        display: 'flex',
        flexDirection: 'column',
        '&.bottom': {
            marginTop: theme.spacing(2),
        },
    },

    alignBottom: {
        flexGrow: 1,
        justifyContent: 'flex-end',
    },
}));

interface PlanProps {
    entity: string;
    subscription: Subscription | null;
    product: Product;
    plan: Plan;
    team: ProfileTeam | null;
    intervalSelector?: React.ReactNode;
    children?: React.ReactNode;
    seatCount?: number;
    addons?: Record<string, string>;
}

function PlanSummary({
    entity,
    product,
    subscription,
    plan,
    team,
    intervalSelector,
    seatCount,
    addons,
    children,
}: PlanProps) {
    const classes = useStyles();
    const isActive = isPlanActive(subscription, product, plan);
    const features = plan.features || [];
    const additionalFeatures = plan.additionalFeatures || [];

    const currentTrial = isActive ? null : getTrial(entity, product, team);
    const trialActive = currentTrial ? isTrialActive(currentTrial) : false;
    const monthlyAmount = computePlanMonthlyAmount(plan, seatCount, addons);

    function renderPaymentInterval() {
        if (plan.isOneTime) {
            return 'one-time';
        }

        if (!plan.billingInterval || plan.billingInterval.intervalCount === 1) {
            return 'per month';
        }

        if (plan.billingInterval.intervalCount === 12) {
            return 'per month paid yearly';
        }

        if (plan.billingInterval.intervalCount === 6) {
            return 'per month paid bi-yearly';
        }

        return `per month paid every ${plan.billingInterval.intervalCount} months`;
    }

    function renderPrice() {
        if (plan.amount.value >= 0) {
            return (
                <>
                    {intervalSelector}

                    <Typography variant="h6" align="center" style={{ lineHeight: 1 }}>
                        {plan.amount.value <= 0 ? (
                            'Free'
                        ) : (
                            <>
                                <PlanAmount amount={monthlyAmount} />
                                <br />
                                <span style={{ fontSize: '70%', fontWeight: 'normal' }}>{renderPaymentInterval()}</span>
                            </>
                        )}
                    </Typography>
                </>
            );
        }

        return (
            <Button
                size="small"
                variant="contained"
                fullWidth
                onClick={() => (window as any).open('https://discord.gg/dWHrM2BbFs')}
            >
                Contact Us
            </Button>
        );
    }

    function renderAction() {
        if (children) {
            return <div className={clsx(classes.planItemGroup, 'bottom')}>{children}</div>;
        }

        if (isActive || trialActive) {
            return (
                <div className={clsx(classes.planItemGroup, 'bottom')}>
                    <Typography variant="caption" color="textSecondary" align="center" component="p">
                        {currentTrial ? (trialActive ? 'in active trial' : 'trial expired') : 'active'}
                    </Typography>
                </div>
            );
        }

        return null;
    }

    return (
        <div className={clsx(classes.plan, (isActive || trialActive) && 'active')} key={plan.name}>
            {plan.special && <div className="special">{plan.special}</div>}

            <div className={classes.planItemGroup}>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }} align="center">
                    {plan.name}
                </Typography>

                <Divider className={classes.planDivider} />

                {features.map((feature: any, i: any) => (
                    <Typography component="div" variant="body1" align="center" key={i}>
                        {feature}
                    </Typography>
                ))}
                {additionalFeatures.map((feature: any, i: any) => (
                    <Typography component="div" variant="body1" align="center" key={i}>
                        {typeof feature === 'string' ? `+ ${feature}` : feature}
                    </Typography>
                ))}
            </div>

            <div className={clsx(classes.planItemGroup, 'bottom')}>
                {renderPrice()}
                {renderAction()}
            </div>
        </div>
    );
}

export default PlanSummary;
