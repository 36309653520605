import React from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import Loading from '../../components/Loading';
import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material';
import { Dropin } from 'braintree-web-drop-in';
import { grey } from '@mui/material/colors';
import ButtonWithAsyncAction from '../../components/ButtonWithAsyncAction';
import { queueMessage } from '../../components/snackbar/reducer';
import { useDispatch } from 'react-redux';

const scriptSrcs = ['https://js.braintreegateway.com/web/dropin/1.37.0/js/dropin.min.js'];

export interface BraintreeDropInProps {
    entity: string;
    onCompleted: () => void;
}

function BraintreeDropIn({ entity, onCompleted }: BraintreeDropInProps) {
    const [isLoaded, setLoaded] = React.useState(false);
    const [clientToken, setClientToken] = React.useState('');
    const [error, setError] = React.useState('');
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [dropInInstance, setDropInInstance] = React.useState<Dropin | null>(null);
    const [isUpdating, setUpdating] = React.useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        async function load() {
            try {
                const res = await axios.post(`/api/payments/braintree/${encodeURIComponent(entity)}/client-token`);
                setClientToken(res.data.ClientToken);
            } catch (e) {
                setError(`${e}`);
            }
        }

        load();
    }, []);

    React.useEffect(() => {
        if (!isLoaded || !clientToken) {
            return;
        }

        // @ts-ignore
        braintree.dropin.create(
            {
                authorization: clientToken,
                // @ts-ignore
                container: containerRef.current,
                card: {
                    vault: {
                        vaultCard: true,
                    },
                },
                paypal: {
                    flow: 'vault',
                },
                threeDSecure: true,
                vaultManager: true,
            },
            // @ts-ignore
            (createErr, dropIn) => {
                if (createErr) {
                    setError(createErr.message);
                    return;
                }

                setDropInInstance(dropIn);
            }
        );
    }, [clientToken, isLoaded]);

    function handleSubmit() {
        if (!dropInInstance) {
            setError('Braintree not fully initialized.');
            return;
        }

        setUpdating(true);
        dropInInstance.requestPaymentMethod(
            { threeDSecure: { amount: '0.00', challengeRequested: true } },
            async (err, payload) => {
                if (err) {
                    // @ts-ignore
                    setError(err.message);
                    setUpdating(false);

                    return;
                }

                let isSuccess = false;
                try {
                    await axios.post(`/api/payments/braintree/${encodeURIComponent(entity)}/payment-methods`, {
                        nonce: payload.nonce,
                    });

                    queueMessage('success', 'Payment method was updated successfully.')(dispatch);
                    isSuccess = true;
                } catch (e) {
                    setError(`${e}`);
                } finally {
                    setUpdating(false);
                }
                if (isSuccess) {
                    onCompleted();
                }
            }
        );
    }

    return (
        <>
            <Helmet
                onChangeClientState={(newState: any, { scriptTags = [] }, removedTags: any) => {
                    const filteredScripts = scriptTags.filter((s) => scriptSrcs.includes(s.src));
                    if (filteredScripts.length > 0) {
                        Promise.all(
                            filteredScripts.map(
                                (s) =>
                                    new Promise((resolve) => {
                                        s.onload = resolve;
                                    })
                            )
                        ).then(() => setLoaded(true));
                    }
                }}
            >
                {scriptSrcs.map((src) => (
                    <script key={src} src={src} type="text/javascript"></script>
                ))}
            </Helmet>
            {error && <Alert severity="error">{error}</Alert>}

            <Box
                sx={{
                    padding: 2,
                    backgroundColor: 'white',
                    marginTop: 2,
                    marginBottom: 2,
                    borderRadius: 2,
                    display: isLoaded && clientToken ? 'block' : 'none',
                }}
                ref={containerRef}
            />
            {(!isLoaded || !clientToken) && <CircularProgress size={30} />}
            {dropInInstance && (
                <ButtonWithAsyncAction
                    disabled={isUpdating}
                    onClick={handleSubmit}
                    variant="contained"
                    size="large"
                    color="primary"
                >
                    Update Payment Method
                </ButtonWithAsyncAction>
            )}
        </>
    );
}

export default BraintreeDropIn;
