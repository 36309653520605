import React, { Component } from 'react';
import PraccPaper from '../../components/paper/PraccPaper';
import PraccPaperHeader from '../../components/paper/PraccPaperHeader';

class ToS extends Component {
    componentDidMount() {}

    render = () => {
        return (
            <PraccPaper>
                <PraccPaperHeader>Terms of Service</PraccPaperHeader>

                <h2>1. Subject Matter</h2>

                <p>
                    1.1. The subject matter of this Agreement is the use of our website and their services. This
                    Agreement regulates all relations between the website (hereinafter "the Service") and the customer
                    ("the Customer") regarding the use of the Service. Read all the terms and conditions carefully
                    before you use the Service. By using the Service you automatically agree to terms and conditions
                    written down in our terms of service.
                </p>

                <p>
                    1.2. The Service communicates with the third-party services for example steamcommunity.com offered
                    by Valve Corporation. Requirement for the proper use of the Service is a valid contract of the
                    Customer with such a third-party service.
                </p>

                <h2>2. Scope of Services</h2>

                <p>
                    2.1. The Service is a platform where you can get an automated matchbot for your practicing matches.
                    At its sole discretion, the Service may modify the functionalities of the Service or discontinue a
                    functionality or the Service at any time without prior notice.
                </p>

                <p>2.2. The Service is not affiliated in any way with Valve Corporation and its affiliates.</p>

                <p>
                    2.3. The Customer acknowledges and understands that the proper working of the Service depends on the
                    availability and proper working of third-party services including but not limited to Valve's
                    Steamcommunity, or the Steam API. The Service assumes no liability and offers no compensation
                    whatsoever for the temporary or permanent unavailability of goods caused by the unavailability of a
                    third-party service or caused by other changes to the functionality of a third-party service.
                </p>

                <h2>3. Payments</h2>

                <p>
                    3.1. All monies stated in this Agreement and in the Service are expressed in Euro and include all
                    applicable taxes. The customer will get charged the amount shown for a choosen feature once he
                    enterred all the necessary info for the chosen payment method.
                </p>

                <p>
                    3.2. When the Customer is purchasing any feature through any of the offered billing services he is
                    aware of buying a virtual good, that there will be no physical delivery. Furthermore, the Customer
                    agrees not to initiate any claim and dispute procedure with third parties regarding the payment like
                    PayPal's "Claims and Dispute” mechanism. The first case of initiating such a claim and dispute
                    procedure will result in a suspension of at least 24 hours from the Service. Any further case of
                    initiation of a claim and dispute procedure will result in automatic suspension of the Customer's
                    individual account and further individual accounts under the Customer's control. The Customer will
                    exclusively submit any disagreement, dispute, complaint or query by opening a support ticket on the
                    Service's website by clicking the "Support" link at the top navigation. Due to the nature of the
                    digital goods, the Service does not issue any refunds on made purchases whatsover, and the Customer
                    waives any withdrawal rights which would otherwise be granted by law when making a purchase and
                    asking the Service to render its services before the withdrawal deadline.
                </p>

                <p>
                    3.3. The Service shall charge all applicable fees on the PayPal or any other billing service account
                    associated to your account at the Service. Therefore the Customer is responsible to provide the
                    Service with valid information for making and receiving payments. The Customer may modify his
                    account information by making a request by opening a support ticket. The Customer must submit a
                    support ticket with his new account information if his payment information changes including
                    describing the reason for the change. If in doubt, the Service may require further information and
                    documents before changing the payment details associated with the Customer's account at the Service.
                </p>

                <p>3.4. The Service reserves the right to show specific Customers only specific payment methods.</p>

                <h2>4. Obligations of the Customer</h2>

                <p>
                    4.1. To use the Service the Customer has to be at least 18 years old regardless of any consent from
                    its parent or guardian. Furthermore, only consumers under section 13 of the German Civil Code can
                    conclude Agreements with the Service.
                </p>

                <p>
                    4.2. The Customer is bound to provide truthful information when asked for a verfication for the
                    Service and to immediately notify the Service of any changes of information that has been requested
                    during the verification on his own. Furthermore the Customer is not allowed to impersonate another
                    person or use a name, for whose use he has no authorization. The Customer authorizes the Service to
                    use any information that has been requested during the verification from time to time as long as the
                    Customer is registered for the Service, for example to verify the accuracy of the information
                    (including updates thereto) and also to obtain reports about his credit rating.
                </p>

                <p>
                    4.3. If the Customer made untrue statements during the verification or fails to inform the Service
                    about changes to the verification, the Customer can be excluded with immediate effect from the
                    Service. The Service reserves the right at any time at its own discretion to reject a verification
                    and to refuse the provision of services provided by the Service.
                </p>

                <p>
                    4.4. Getting access to the Service the Customer signs in and registers through his Steam account
                    provided by Valve Corporation. The Customer is responsible to manage his Steam account and to take
                    measures for its security. Furthermore, the Customer agrees to be responsible for all operations
                    that are performed with his Steam account on the Service's website and is not allowing any
                    third-party the use of the Service with his Steam account.
                </p>

                <p>
                    4.5. The Customer must not interfere or intent to interfere in any manner with the functionality or
                    proper working of the Service.
                </p>

                <p>
                    4.6. The Customer will indemnify and hold harmless towards the Service, its officers and directors,
                    employees and agents from any and all third-party claims, damages, costs (including reasonable
                    attorneys fees) arising out of the Customer's use of the Service in a manner not authorized by this
                    Agreement, and/or applicable law, or the Customer's personnel's negligence or willful misconduct.
                </p>

                <h2>5. Data Protection and Confidentiality</h2>

                <p>
                    5.1. The Service stores account data, third-party credentials and user information about the
                    Customer. This data may be shared with third parties if those are assigned by the Service to handle
                    accounting, payment or other internal processes.
                </p>

                <p>
                    5.2. The Services automatically collets and stores standard internet log information when the
                    Customer visits the Website. This information may include, but is not limited to, the Customer's
                    Uniform Resource Locator (“URL”), its Internet Protocol (“IP”) address and domain name used, its
                    type of browser, the date, time and length of its visits as well as the searches conducted and the
                    web pages consulted. These data will be kept confidential and used mainly regarding our accounting.
                </p>

                <p>
                    5.3. The Service may inform the public about the Customer using the Service including a rough
                    description of the usage for marketing and public relation purposes. The Customer agrees to appear
                    in the Service's reference lists including any name, trademark or logo of the Customer. This
                    includes, but is not limited to, descriptions on the Website, any other websites, presentations,
                    presentation material and press announcements of the Service.
                </p>

                <p>
                    5.4. The Service reserves the right given by the Customer to check all data entered and documents
                    sent by the Customer. These data will be treated confidentially and will be used to prevent fraud
                    and abuse of the Service.
                </p>

                <p>
                    5.5. The Service will have the right to investigate and prosecute violations of any of the above,
                    including without limitation possible infringement of any intellectual property rights and possible
                    security breaches, to the fullest extent of the law. Therefore the Service may involve and cooperate
                    with law enforcement authorities in prosecuting users who violate this Agreement.
                </p>

                <h2>6. Term and Termination</h2>

                <p>
                    6.1. The Agreement runs for an indefinite time and will remain in effect until terminated by one of
                    the Parties in accordance with section 6 of these terms.
                </p>

                <p>
                    6.2. The Parties may terminate this Agreement for any or no reason at their convenience with a 30
                    day notice to the end of each month. Termination may be issued in writing or by using the provided
                    account closing mechanism if provided by the Service.
                </p>

                <p>
                    6.3 In addition the Service has the right to terminate this Agreement for a good cause for example,
                    but not limited to, the following:
                    <ul>
                        <li>a serious breach of obligations arising from this Agreement by the Customer;</li>

                        <li>
                            a serious breach of contract leading to the loss of mutual trust which renders the
                            continuation of this Agreement in consideration of the purpose of the Agreement unreasonable
                        </li>

                        <li>
                            an attempt at a denial of service attack on any of the Service's services by the Customer,
                            or any attempt to hack or break any security mechanism on any of the Service's services;
                        </li>

                        <li>
                            a major change in the working of third-party services including but not limited to Valve's
                            Steamcommunity which make it unreasonable to adapt the Service accordingly or make further
                            offering of the Service unreasonable.
                        </li>
                    </ul>
                </p>

                <p>
                    6.4 Lifetime plans are valid for the lifetime of the offered product, or a successor product. The
                    product will be offered for a minimum of two years starting on the purchase date of the lifetime
                    plan.
                </p>

                <h2>7. Right of Withdrawal</h2>

                <p>
                    If you are a consumer, you have the right to withdraw from this contract within 14 days without
                    giving any reason.
                </p>

                <p>The withdrawal period will expire after 14 days from the day of the conclusion of the contract.</p>

                <p>
                    To exercise the right of withdrawal, you must inform us (stonefire GmbH, Kuhbergstr. 37, 34131
                    Kassel) of your decision to withdraw from this contract by an unequivocal statement (e.g. a letter
                    sent by post or an e-mail sent to support@pracc.com). You may use the attached model withdrawal
                    form, but it is not obligatory.
                </p>

                <p>
                    To meet the withdrawal deadline, it is sufficient for you to send your communication concerning your
                    exercise of the right of withdrawal before the withdrawal period has expired.
                </p>

                <p>
                    <strong>Effects of withdrawal</strong>
                    <br></br>If you withdraw from this contract, we shall reimburse to you all payments received from
                    you, including the costs of delivery (with the exception of the supplementary costs resulting from
                    your choice of a type of delivery other than the least expensive type of standard delivery offered
                    by us), without undue delay and in any event not later than 14 days from the day on which we are
                    informed about your decision to withdraw from this contract. We will carry out such reimbursement
                    using the same means of payment as you used for the initial transaction, unless you have expressly
                    agreed otherwise; in any event, you will not incur any fees as a result of such reimbursement.
                </p>

                <p>
                    THE CUSTOMER IS AWARE AND AGREES THAT THE RIGHT OF WITHDRAWAL EXPIRES IF HE ASKS THE SERVICE TO
                    RENDER ITS SERVICES BEFORE THE WITHDRAWAL DEADLINE, AND DUE TO THE NATURE OF THE DIGITAL GOODS A
                    WITHDRAWAL IS IMPOSSIBLE.
                </p>

                <p>
                    <strong>Model withdrawal form</strong>
                    <br></br>
                    <p>(complete and return this form only if you wish to withdraw from the contract)</p>

                    <ul>
                        <li>
                            To<br></br>stonefire GmbH<br></br>Kuhbergstr. 37<br></br>34131 Kassel
                        </li>

                        <li>
                            I/We (*) hereby give notice that I/We (*) withdraw from my/our (*) contract of sale of the
                            following goods (*)/for the provision of the following service (*),
                        </li>
                        <li>Ordered on (*)/received on (*),</li>
                        <li>Name of consumer(s),</li>
                        <li>Address of consumer(s),</li>
                        <li>Signature of consumer(s) (only if this form is notified on paper),</li>
                        <li>Date</li>
                    </ul>

                    <p>(*) delete as appropriate</p>
                </p>

                <h2>8. Disputes, Applicable Law, Notices</h2>

                <p>
                    8.1. This Agreement and any dispute, controversy, proceeding or claim of whatever nature arising out
                    of, or in any way relating to this Agreement or its formation shall be governed by material German
                    law. The UN Convention on Contracts for the International Sale of Goods (CISG) shall not apply.
                </p>

                <p>
                    8.2. The parties agree that the courts of the seat of the Service shall have exclusive jurisdiction
                    (so far this is permitted by law) to settle any dispute, controversy, proceeding or claim of
                    whatever nature arising out of, or in any way relating to this Agreement or its formation.
                </p>

                <p>
                    8.3. Notices made by the Service to the Customer may be posted on the Website and/or send to the
                    email address specified by the Customer when signing up or to any updated email address the customer
                    provides. Notices to the Services must be handed in via our support or directed to
                    <a href="mailto:support@pracc.com">support@pracc.com</a>
                </p>

                <p>
                    8.4. The official text of this Agreement and any annexes attached here to and any notices given here
                    shall be in English. However communication between the Service and the Customer may be in English or
                    German.
                </p>

                <h2>9. Final Provisions</h2>

                <p>
                    9.1. This Agreement, together with any documents referred to herein, or expressed to be entered into
                    in connection with it, constitutes the whole agreement between the Parties concerning the subject
                    matter of this Agreement. Any ancillary verbal agreements are void.
                </p>

                <p>
                    9.2. The Customer may set off only legally binding and recognized claims. The rights and obligations
                    arising from this Agreement are generally not transferable. However, the Service may transfer this
                    Agreement with all rights and obligations to a company of its choice.
                </p>

                <p>
                    9.3. These terms of service may be modified by the Service at any time. The Service will inform the
                    Customer via email that these terms of service have been altered without necessarily including the
                    new version or alteration in this email. If the Customer does not expressly disagree in writing
                    within a month from the notification of change, this is deemed to be the acceptance of the change.
                    The customer will expressly be informed about this consequence and the significance of his silence
                    in the email notifying about the change.
                </p>
            </PraccPaper>
        );
    };
}

export default ToS;
