import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, ButtonProps, darken } from '@mui/material';
import { grey } from '@mui/material/colors';
import { FaSteam as SteamIcon } from 'react-icons/fa';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import AccountLoginButton, { OAuthLoginButtonProps } from '../auth/AccountLoginButton';
import AccountProviderIcon from '../auth/AccountProviderIcon';
import AccountLoginWaitDialog from '../auth/AccountLoginWaitDialog';

export const SteamButton = styled((props: ButtonProps & Pick<OAuthLoginButtonProps, 'onSuccess'>) => (
    <AccountLoginButton startIcon={<SteamIcon />} provider="steam" {...props} />
))({
    backgroundColor: grey[800],
    color: 'white',

    '&:hover': {
        backgroundColor: darken(grey[800], 0.3),
    },
});

export const RiotLoginButton = styled((props: ButtonProps & Pick<OAuthLoginButtonProps, 'onSuccess'>) => (
    <AccountLoginButton startIcon={<AccountProviderIcon provider="riot" />} provider="riot" {...props} />
))({
    backgroundColor: '#ad1414',
    color: 'white',
    '&:hover': {
        backgroundColor: darken('#ad1414', 0.3),
    },
});

export const DiscordLoginButton = styled((props: ButtonProps & Pick<OAuthLoginButtonProps, 'onSuccess'>) => (
    <AccountLoginButton startIcon={<AccountProviderIcon provider="discord" />} provider="discord" {...props} />
))({
    backgroundColor: '#7289DA',
    color: 'white',
    '&:hover': {
        backgroundColor: darken('#7289DA', 0.3),
    },
});

export const ButtonLink = styled((props: Omit<ButtonProps, 'component'> & LinkProps) => (
    <Button component={RouterLink} {...props} />
))({
    textDecoration: 'none',
});
