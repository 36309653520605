import {
    Avatar,
    Box,
    CircularProgress,
    Divider,
    Drawer,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import React from 'react';
import { ListView, MobileNotificationHeader } from '.';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { resolveTimeFormatWithMonth } from 'utils/i18n';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { handleError } from 'components/snackbar/reducer';
import {
    addNotification,
    loadProfile,
    openChat,
    openMsgs,
    resendConfirmationEmail,
    selectTeam,
    setEmailUnconfirmedHidden,
    setRatingIsOpenHidden,
    setUnreadMsgs,
    showNotifySnack,
    updateIsLoadingNotifications,
    updateProfile,
    updateTeamProfile,
} from './reducer';
import { makeStyles } from '@mui/styles';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

function NotificationDrawer(props: any) {
    const { isLoadingNotifications, handleCloseDrawer, notifications, profile, selectTeam } = props;
    const history = useHistory();
    const isMobileView = window.innerWidth <= 768;

    return (
        <Box id="notificationDrawer">
            <MobileNotificationHeader
                sx={{
                    backgroundColor: 'rgb(12, 17, 38)',
                    backgroundImage: 'radial-gradient(ellipse farthest-side, rgba(0, 111, 255, 0.08), transparent)',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                }}
                style={{ padding: '0.9rem 10px' }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            borderBottom: '2px solid #F50057',
                        }}
                        variant="h6"
                    >
                        Notifications
                    </Typography>
                </Box>

                {isMobileView && (
                    <IconButton onClick={handleCloseDrawer}>
                        <KeyboardReturnIcon />
                    </IconButton>
                )}
            </MobileNotificationHeader>
            <div tabIndex={0} role="button" onClick={handleCloseDrawer} onKeyDown={handleCloseDrawer}>
                <ListView>
                    {isLoadingNotifications ? (
                        <ListItem>
                            <CircularProgress size={50} />
                        </ListItem>
                    ) : notifications.length > 0 ? (
                        notifications.map(
                            (n: {
                                MatchID: string | null;
                                OfferID: string | null;
                                TeamID: any;
                                id: React.Key | null | undefined;
                                CreatedAt: moment.MomentInput;
                                AvatarUrl: string | undefined;
                                What: any;
                                Who: any;
                                MatchTime: moment.MomentInput;
                            }) => {
                                let targetUrl = '';

                                if (n.MatchID !== null) {
                                    targetUrl = '/matches/' + n.MatchID;
                                } else if (n.OfferID !== null) {
                                    targetUrl = '/search/offers/' + n.OfferID;
                                }

                                let toTeam;
                                if (n.TeamID && profile) {
                                    toTeam = profile.Teams.find((team: { ID: any }) => team.ID === n.TeamID);
                                    if (!toTeam) {
                                        return null;
                                    }
                                }

                                const styles = targetUrl ? { cursor: 'pointer' } : {};
                                return (
                                    <>
                                        <ListItem
                                            key={n.id}
                                            style={styles}
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor: '#2c324a',
                                                },
                                            }}
                                            onClick={() => {
                                                if (targetUrl) {
                                                    if (n.TeamID && profile.Team.ID !== n.TeamID) {
                                                        selectTeam(n.TeamID);
                                                    }

                                                    history.push(targetUrl);
                                                }
                                            }}
                                            secondaryAction={
                                                <React.Fragment>
                                                    <Typography variant="caption">
                                                        {profile &&
                                                            moment(n.CreatedAt)
                                                                .tz(profile.Settings.LocalTimezone)
                                                                .fromNow()}
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                        >
                                            <ListItemAvatar>
                                                <Avatar alt="Avatar" src={n.AvatarUrl} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={`${n.What} by ${n.Who}`}
                                                secondary={
                                                    <React.Fragment>
                                                        {profile
                                                            ? n.MatchTime
                                                                ? moment(n.MatchTime)
                                                                      .tz(profile.Settings.LocalTimezone)
                                                                      .format(
                                                                          resolveTimeFormatWithMonth(
                                                                              profile.Settings.TimeFormat
                                                                          )
                                                                      )
                                                                : moment(n.CreatedAt)
                                                                      .tz(profile.Settings.LocalTimezone)
                                                                      .format(
                                                                          resolveTimeFormatWithMonth(
                                                                              profile.Settings.TimeFormat
                                                                          )
                                                                      )
                                                            : null}
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                        <Divider variant="middle" sx={{ width: '100%', margin: '0px' }} />
                                    </>
                                );
                            }
                        )
                    ) : (
                        <ListItem>
                            <ListItemText primary={'No Notifications to show'} />
                        </ListItem>
                    )}
                </ListView>
            </div>
        </Box>
    );
}

export default connect(
    (state: any) => ({
        profile: state.app.profile,
        isLoading: state.app.isLoading,
        isResendingConfirmationEmail: state.app.isResendingConfirmationEmail,
        isEmailUnconfirmedAlertHidden: state.app.isEmailUnconfirmedAlertHidden,
        isRatingOpenAlertHidden: state.app.isRatingOpenAlertHidden,
        msgDrawerOpen: state.app.msgDrawerOpen,
        notifications: state.app.notifications,
        websocket: state.app.websocket,
        isLoadingNotifications: state.app.isLoadingNotifications,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                openChat,
                selectTeam,
                loadProfile,
                updateProfile,
                updateTeamProfile,
                resendConfirmationEmail,
                setEmailUnconfirmedHidden,
                openMsgs,
                showNotifySnack,
                addNotification,
                setUnreadMsgs,
                setRatingIsOpenHidden,
                updateIsLoadingNotifications,
            },
            dispatch
        )
)(NotificationDrawer);
