import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

function NotFoundPage() {
    return <div style={{display: 'flex', flexGrow: 1, flexDirection: 'column'}}>
        <Paper style={{padding: 20}}>
            <Typography variant="h5" align="center">Sorry, the requested page was not found.</Typography>
        </Paper>
    </div>
}

export default NotFoundPage