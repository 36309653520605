export function resolveFullTimeFormat(timeFormat: string): string {
    if (timeFormat === 'na') {
        return 'MM/DD/YYYY - h:mm a zz';
    }

    return 'DD.MM.YYYY - HH:mm zz';
}

export function compoundFullTimeFormat(timeFormat: string): string {
    if (timeFormat === 'na') {
        return 'MM/DD/YYYY h:mm a zz';
    }

    return 'DD.MM.YYYY HH:mm zz';
}

export function resolveTimeFormatWithWeekday(timeFormat: string): string {
    if (timeFormat === 'na') {
        return 'dddd, MMM DD, h:mm a zz';
    }

    return 'dddd Do, HH:mm zz';
}

export function resolveTimeFormatWithMonth(timeFormat: string): string {
    if (timeFormat === 'na') {
        return 'MMMM Do YYYY, h:mm a zz';
    }

    return 'MMMM Do YYYY, HH:mm zz';
}

export function resolveTimeFormatWithWeekdaySt(timeFormat: string): string {
    if (timeFormat === 'na') {
        return 'dddd, MMMM Do, h:mm a zz';
    }

    return 'dddd Do MMMM HH:mm zz';
}

export function resolveHourTimeFormat(timeFormat: string): string {
    if (timeFormat === 'na') {
        return 'h:mm a';
    }

    return 'HH:mm';
}

export function resolveDateFormat(timeFormat: string): string {
    if (timeFormat === 'na') {
        return 'MM/DD/YYYY';
    }

    return 'DD.MM.YYYY';
}

export function isAmPm(timeFormat: string): boolean {
    return timeFormat === 'na';
}
