import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

interface CenteredPageProps {
    children: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
    Container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingBottom: '0.5rem'
        // overflowY: 'scroll',
        // msOverflowStyle: 'none',
        // scrollbarWidth: 'none'
    },
    contentWrapper: {
        width: '100%',
        // marginTop: '0.3rem',
        // marginBottom: '1rem',
        // [theme.breakpoints.down('lg')]: {
        //     width: '100%',
        // },
    },
    pageWrapper: {
        width: '100%',
        '@media (max-width: 1024px)': {
            paddingBottom: '11.5rem',
            height: '100%'
        },
    },

    singlePageWrapper: {
        width: '100%',
        '@media (max-width: 1024px)': {
            height: '100%',
            paddingBottom: '7.5rem',
        },
    }
}));

function CenteredPage(props: CenteredPageProps) {
    const classes = useStyles();

    return <Box className={classes.Container}>{props.children}</Box>;
}

export default CenteredPage;

export const NewContainer = (props: CenteredPageProps) => {
    const classes = useStyles();
    return   <Box className={classes.contentWrapper}>{props.children}</Box>
}

export const PageContainer = (props: CenteredPageProps) => {
    const classes = useStyles();
    return   <Box className={classes.pageWrapper}>{props.children}</Box>
}

export const SinglePageContainer = (props: CenteredPageProps) => {
    const classes = useStyles();
    return   <Box className={classes.singlePageWrapper}>{props.children}</Box>
}
