import React from 'react';
import { AccountProvider } from '../../model/profile';
import {
    FaSteam as SteamIcon,
    FaDiscord as DiscordIcon,
    FaTwitch as TwitchIcon,
    FaGoogle as GoogleIcon,
} from 'react-icons/fa';
import { SiFaceit as FaceitIcon, SiRiotgames as RiotIcon, SiBattledotnet as BattleNetIcon } from 'react-icons/si';
import ValorantIcon from './ValorantIcon';
import LeagueOfLegendsIcon from './LeagueOfLegendsIcon';

interface AccountProviderIconProps {
    provider: AccountProvider;
    size?: number;
}

function AccountProviderIcon({ provider, size }: AccountProviderIconProps) {
    if (provider === 'steam') {
        return <SteamIcon size={size} />;
    } else if (provider === 'discord') {
        return <DiscordIcon size={size} />;
    } else if (provider === 'twitch') {
        return <TwitchIcon size={size} />;
    } else if (provider === 'faceit') {
        return <FaceitIcon size={size} />;
    } else if (provider === 'riot') {
        return <RiotIcon size={size} />;
    } else if (provider === 'google') {
        return <GoogleIcon size={size} />;
    } else if (provider === 'battlenet') {
        return <BattleNetIcon size={size} />;
    }

    return null;
}

export default AccountProviderIcon;
