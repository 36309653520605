import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { grey } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import { addMessages } from './reducerChat';
import Scrollbar from 'react-scrollbars-custom';
import { emphasize, TextField } from '@mui/material';
import PraccPaperHeader from '../../components/paper/PraccPaperHeader';
import {TeamName} from '../../../src/containers/matches/match';

const Container = styled('div')`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 210px);

    @media only screen and (min-width: 768px) {
        height: 100%;
    }
`;

const MessagesContainer = styled('div')`
    flex: 1;
    display: flex;
    overflow: hidden;
    height: 100%;
`;

const Messages = styled('ul')`
    min-height: min-content;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 0;
`;

const MessageContainer = styled('li')`
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
`;


const Title = styled('div')`
    line-height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (min-width: 768px) {
        justify-content: flex-start;
    }
`;
const PersonWrapper = styled('div')`
    display: flex;
    flex-direction: column;
`;
const PersonaName = styled('div')`
    font-weight: bold;
`;
const ContentContainer = styled('div')`
    margin-left: 10px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 4px;

    @media only screen and (min-width: 768px) {
        height: 100%;
        flex-grow: 0;
    }
`;

const TitleTag = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(2),
    },
    padding: '0 5px',
    fontSize: 12,
    backgroundColor: emphasize(theme.palette.background.paper, 0.15),
    // height: '20px',
    [theme.breakpoints.down('sm')]: {
        maxWidth: '100px'
    },
}));

const PositionTag = styled('div')(({ theme }) => ({
    fontSize: 12,
    backgroundColor: emphasize(theme.palette.background.paper, 0.15),
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(2),
    },
}));

const AdminTag = styled(TitleTag)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
}));

const Text = styled('div')`
    line-height: 20px;
`;

class Message extends Component {
    render() {
        const personaName = this.props.PersonaName;
        const teamName = this.props.TeamName;
        const position = this.props.Position;
        const time = this.props.Time;
        const message = this.props.Message;
        const avatarURL = this.props.AvatarURL;
        const profile = this.props.profile;

        const dateString = moment(time).tz(profile.Settings.LocalTimezone).calendar();
        const dateStringArr = dateString.split('at');
        const isMobile = window.innerWidth <= 767;

        return (
            <MessageContainer>
                <Avatar size={20} src={avatarURL} />

                <ContentContainer>
                    <Title>
                        <PersonWrapper>
                            <PersonaName>{personaName}</PersonaName>
                            {isMobile && (position === 'Site Admin' ? <AdminTag>{position}</AdminTag> : <PositionTag>{position}</PositionTag>)}
                        </PersonWrapper>
                        {!isMobile && (position === 'Site Admin' ? <AdminTag>{position}</AdminTag> : <PositionTag>{position}</PositionTag>)}
                        {position !== 'Site Admin' && <TitleTag>{teamName}</TitleTag>}
                        {isMobile ? (
                            <div>
                                <TitleTag>{`${dateStringArr[0]}`}</TitleTag>
                                <TitleTag>{dateStringArr[1]}</TitleTag>
                            </div>
                        ) : (
                            <TitleTag>{moment(time).tz(profile.Settings.LocalTimezone).calendar()}</TitleTag>
                        )}
                    </Title>
                    
                    <Text>{message}</Text>
                </ContentContainer>
            </MessageContainer>
        );
    }
}

const ChatCircularProgress = styled(CircularProgress)`
    align-self: center;
    margin-bottom: 20px;
`;

class ChatPanel extends Component {
    state = {
        input: '',
    };

    handleTextChange(e) {
        if (e.keyCode === 13) {
            if (e.target.value !== '') {
                this.props.websocket.send('match.SendChatMessage', {
                    MatchID: this.props.matchId,
                    TeamID: this.props.profile.Team.ID,
                    Message: e.target.value,
                });

                this.setState({ input: '' });
            }
        } else {
            this.setState({ input: e.target.value });
        }
    }

    subscriptions = [];

    componentDidMount() {
        this.subscriptions.push(
            this.props.websocket.subscribe((event) => {
                switch (event.MessageType) {
                    case 'match.UpdateChatMessages':
                        if (event.Payload.MatchID === this.props.matchId) {
                            let isFirst = true;
                            for (const message of event.Payload.Messages) {
                                this.props.addMessages(this.props.matchId, message, isFirst);
                                isFirst = false;
                            }
                        }
                        break;

                    case 'match.NewChatMessage':
                        if (event.Payload.MatchID === this.props.matchId) {
                            this.props.addMessages(this.props.matchId, event.Payload.Message);
                        }
                        break;
                }
            }),
            this.props.websocket.subscribeConnectionStatus((connected) => {
                if (!connected) {
                    return;
                }

                this.props.websocket.send('match.SubscribeChat', {
                    MatchID: this.props.matchId,
                    TeamID: this.props.profile.Team.ID,
                });
            })
        );

        // this.scrollbar.scrollToBottom();
    }

    componentWillUnmount() {
        this.props.websocket.send('match.UnsubscribeChat');

        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // this.scrollbar.scrollToBottom();
    }

    render = () => {
        let twohoursago = new Date(this.props.matchTime);
        twohoursago.setTime(twohoursago.getTime() + 2 * 60 * 60 * 1000);
        const isMobile = window.innerWidth <= 767;
        
        return (
            <Container>
                {isMobile && (
                    <PraccPaperHeader
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '60px',
                            color: 'white',
                            fontSize: 24,
                        }}
                    >
                            CHAT   
                    </PraccPaperHeader>
                )}
                <MessagesContainer>
                    <Scrollbar
                        ref={(ref) => (this.scrollbar = ref)}
                        style={{ width: '100%', height: '100%', minHeight: 440 }}
                        // thumbYRenderer={(props) => {
                        //     const { elementRef, ...restProps } = props;
                        //     return <div {...restProps} ref={elementRef} className='thumb-vertical' />
                        // }}
                    >
                        <Messages>
                            {this.props.isLoading && <ChatCircularProgress size={20} thickness={2} />}
                            {this.props.messages.map((m) => {
                                return <Message key={m.ID} {...m} profile={this.props.profile} />;
                            })}
                            <div
                                ref={(el) => {
                                    this.messagesEnd = el;
                                }}
                            />
                        </Messages>
                    </Scrollbar>
                </MessagesContainer>
                {!moment(new Date()).isAfter(twohoursago) && (
                    <TextField
                        placeholder="Type a message..."
                        value={this.state.input}
                        onChange={(e) => this.handleTextChange(e)}
                        onKeyDown={(e) => this.handleTextChange(e)}
                    />
                )}
            </Container>
        );
    };
}

export default connect(
    (state, props) => {
        const chat = state.matchChat[props.matchId];
        const id = props.matchId;

        return {
            messages: chat != null ? chat.messages : [],
            isLoading: chat != null ? chat.isLoading : true,
            matchId: id != null ? id : 0,
            websocket: state.app.websocket,
            profile: state.app.profile,
            team: state.app.profile.Team,
            matchTime: props.matchTime,
        };
    },
    (dispatch) => bindActionCreators({ addMessages }, dispatch)
)(ChatPanel);
