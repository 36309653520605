import { ProductId, products } from '../model/products';
import React from 'react';
import { useSelector } from 'react-redux';
import { Profile } from '../model/profile';
import { Subscription } from '../model/subscription';

export type ActivePlan = {
    Plan: string;
    PlanAttributes: Record<string, any>;
    PlanAddons: Record<string, string>;
    Subscription?: Subscription;
};

export function useActivePlan(product: ProductId): ActivePlan | null {
    const profile = useSelector((state: any) => state.app.profile) as Profile | null;

    return React.useMemo(() => {
        if (!profile) {
            return null;
        }

        const teamSubscription = profile.Team?.Subscriptions?.find((s) => s.IsActive && product in s.ResolvedItems);
        if (teamSubscription) {
            const item = teamSubscription.ResolvedItems[product];

            if (
                !item.isSeatBased ||
                teamSubscription.Seats.some((seat) => seat.ProductID === product && seat.UserID === profile.ID)
            ) {
                return {
                    Plan: item.plan as string,
                    PlanAttributes: item.planAttributes as Record<string, any>,
                    Subscription: teamSubscription,
                    PlanAddons: item.addons as Record<string, string>,
                };
            }
        }

        const productTrial = profile.Team?.ProductTrials?.[product];
        if (productTrial?.IsActive) {
            return {
                Plan: productTrial.Plan as string,
                PlanAttributes: {} as Record<string, any>,
                PlanAddons: {} as Record<string, string>,
            };
        }

        const personalSubscription = profile.Subscriptions.find((s) => s.IsActive && product in s.Items);
        const item = personalSubscription?.Items?.[product];
        if (item) {
            return {
                Plan: item.plan as string,
                PlanAttributes: item.planAttributes as Record<string, any>,
                Subscription: personalSubscription,
                PlanAddons: item.addons as Record<string, string>,
            };
        }

        return null;
    }, [profile?.Subscriptions, product, profile?.Team?.Subscriptions, profile?.Team?.ProductTrials]);
}
