import React from 'react';
import styled from 'styled-components';
import { CircularProgress, Paper } from '@mui/material';
import { CSSTransition } from 'react-transition-group';
import { handleError } from '../../../components/snackbar/reducer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loadable from 'react-loadable';
import { openChat } from '../reducer';

const Loading = () => (
    <div>
        <CircularProgress size={30} />
    </div>
);

export const AsyncChatContent = Loadable({
    loader: () => import('./chat-content'),
    loading: Loading,
});

const ChatOverlay = styled('div')`
    padding-top: 41px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 5;
`;

const ChatPaper = styled(Paper)`
    width: 60vw;
    min-width: 500px;
    padding: 0;
    min-height: 60vh;
    border-radius: 0 4px 4px 0;
    margin-left: 25rem;

    transform: translateX(-105%);
    transition: transform 0.25s ease-out;

    display: flex;
    flex-direction: column;

    &.chat-overlay-open {
        transform: translateX(0);
        transition: transform 0.25s ease-out;
    }

    @media (max-width: 1024px) {
        width: 95vw;
        min-width: auto;
    }
`;

class ChatContainer extends React.Component {
    chatOverlayRef = null;

    state = {
        isInitialRendering: true,
        isPaperOpen: false,
    };

    constructor(props) {
        super(props);

        this.chatOverlayRef = React.createRef();
    }

    componentDidMount() {
        this.setState({ isInitialRendering: false });
    }

    previousOverflow = null;

    onEnter() {
        this.setState({ isPaperOpen: true });
        const { mainContainerRef } = this.props;

        if (mainContainerRef && mainContainerRef.current) {
            const scrollTop = mainContainerRef.current.scrollTop;
            this.previousOverflow = mainContainerRef.current.style.overflow;
            mainContainerRef.current.style.overflow = 'hidden';

            if (this.chatOverlayRef.current) {
                this.chatOverlayRef.current.style.transform = 'translateY(' + scrollTop + 'px)';
            }
        }
    }

    onExit() {
        this.setState({ isPaperOpen: false });
    }

    onExited() {
        const { mainContainerRef } = this.props;
        if (!mainContainerRef.current) {
            return;
        }

        mainContainerRef.current.style.overflow = this.previousOverflow;
    }

    render() {
        const { selectedChatId } = this.props;
        const isOpen = !!selectedChatId;

        return (
            <CSSTransition
                in={isOpen && !this.state.isInitialRendering}
                classNames={'chat-overlay'}
                timeout={250}
                mountOnEnter
                unmountOnExit
                onEnter={() => this.onEnter()}
                onExit={() => this.onExit()}
                onExited={() => this.onExited()}
            >
                <ChatOverlay ref={this.chatOverlayRef}>
                    <ChatPaper className={[this.state.isPaperOpen && 'chat-overlay-open']}>
                        <AsyncChatContent chatId={selectedChatId} />
                    </ChatPaper>
                </ChatOverlay>
            </CSSTransition>
        );
    }
}

const Connected = connect(
    (state) => ({
        selectedChatId: state.app.selectedChatId,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                handleError,
                openChat,
            },
            dispatch
        )
)(ChatContainer);

export default Connected;
