import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Profile } from '../../model/profile';
import {
    Avatar,
    Button,
    ButtonGroup,
    ButtonProps,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@mui/material';
import BoostGate from '../boost/BoostGate';
import { PraccDialog } from '../layout/dialogs';
import axios from 'axios';
import { handleError, QUEUE_MESSAGE } from '../snackbar/reducer';
import BlockIcon from '@mui/icons-material/Block';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';

interface BlockTeam {
    ID: number;
    Name: string;
    Logo: string;
}

interface BlockTeamButtonProps extends ButtonProps {
    team: BlockTeam;
    buttonSx?: SystemStyleObject;
}

function BlockTeamButton({ team, buttonSx = {}, ...rest }: BlockTeamButtonProps) {
    const dispatch = useDispatch();
    const profile = useSelector((state: any) => state.app.profile) as Profile;
    const currentTeam = profile?.Team;
    const [showDialog, setShowDialog] = React.useState(false);
    const [blockNote, setBlockNote] = React.useState('');
    const [isWorking, setWorking] = React.useState(false);

    const isBlocked = React.useMemo(() => {
        if (!currentTeam) {
            return false;
        }

        return currentTeam?.BlockedTeams?.some((t) => t.BlockedTeamID === team.ID);
    }, [currentTeam, team]);

    React.useEffect(() => {
        if (showDialog) {
            setBlockNote('');
        }
    }, [showDialog]);

    async function removeBlockedTeam() {
        setWorking(true);
        try {
            await axios.delete('/api/team/blacklist', {
                data: {
                    TeamID: team.ID,
                },
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Team block removed',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
        setWorking(false);
    }

    async function handleBlockTeam() {
        setWorking(true);
        try {
            await axios.post('/api/team/blacklist', {
                TeamID: team.ID,
                Note: blockNote,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Team Blocked!',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
        setWorking(false);
    }

    if (currentTeam?.ID === team.ID) {
        return null;
    }

    if (isBlocked) {
        return (
            <Button
                color={'error'}
                variant={'contained'}
                sx={{ ...buttonSx }}
                onClick={() => removeBlockedTeam()}
                disabled={isWorking}
            >
                Unblock Team
            </Button>
        );
    }

    return (
        <>
            <BoostGate
                type="team"
                featureId="block_list"
                requiredPropertyCount={currentTeam?.BlockedTeams?.length + 1}
                boxSx={buttonSx}
            >
                <Button
                    startIcon={<BlockIcon />}
                    color={'primary'}
                    variant={'contained'}
                    {...rest}
                    onClick={() => setShowDialog(true)}
                >
                    Block Team
                </Button>
            </BoostGate>
            <PraccDialog open={showDialog} onClose={() => setShowDialog(false)} fullWidth>
                <DialogTitle sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    Block <Avatar src={team.Logo} sx={{ marginLeft: 1, marginRight: 1 }} /> {team.Name}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>Do you want to block the team?</DialogContentText>
                    <TextField
                        sx={{ marginTop: 1 }}
                        variant="outlined"
                        autoFocus
                        margin="dense"
                        id="note"
                        label="Note"
                        placeholder="Leave a note for yourself why you block them"
                        type="text"
                        multiline
                        rows={3}
                        maxRows={3}
                        fullWidth
                        value={blockNote}
                        onChange={(e) => setBlockNote(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <ButtonGroup variant={'contained'}>
                        <Button
                            startIcon={<BlockIcon />}
                            color={'primary'}
                            disabled={isWorking}
                            onClick={handleBlockTeam}
                        >
                            Block Team
                        </Button>
                        <Button color={'secondary'} disabled={isWorking} onClick={() => setShowDialog(false)}>
                            Close
                        </Button>
                    </ButtonGroup>
                </DialogActions>
            </PraccDialog>
        </>
    );
}

export default BlockTeamButton;
