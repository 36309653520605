import React from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from '@mui/material';
import { FaExternalLinkAlt as ExternalLinkIcon } from 'react-icons/fa';
import { PraccDialog } from '../layout/dialogs';
import { openAuthWindow } from './utils';
import CloseIcon from '@mui/icons-material/Close';

interface OAuthLoginWaitDialogProps {
    title: React.ReactNode;
    open: boolean;
    validating: boolean;
    onClose: () => void;
    authUrl?: string;
}

function AccountLoginWaitDialog({ title, open, validating, onClose, authUrl }: OAuthLoginWaitDialogProps) {
    return (
        <PraccDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <span>{title}</span>
                    {!validating && (
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    )}
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {validating
                        ? 'Validating authentication results...'
                        : 'Please follow the instructions in the new window that opened to complete the authentication.'}
                </DialogContentText>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: 2,
                    }}
                >
                    <CircularProgress size={60} color="primary" />
                </Box>

                {authUrl && !validating && (
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            openAuthWindow(authUrl);
                        }}
                        endIcon={<ExternalLinkIcon size={15} />}
                    >
                        Reopen External Page
                    </Button>
                )}
            </DialogContent>
        </PraccDialog>
    );
}

export default AccountLoginWaitDialog;
