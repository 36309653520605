import React from 'react';
import Icon from '@mui/material/Icon';
import { black } from '@mui/material/colors';

export default (params) => {
    return (
        <Icon
            {...params}
            style={{
                fontSize: '20px',
                ...params.style,
            }}
        >
            {params.children}
        </Icon>
    );
};
