import axios from 'axios';
import { QUEUE_MESSAGE } from 'components/snackbar/reducer';
import { handleError } from '../../components/snackbar/reducer';
import { REMOVE_TEAM_PROFILE } from '../app/reducer';

export const SET_TEAM_NAME = 'team/SET_TEAM_NAME';
export const SET_TEAM_DESCRIPTION = 'team/SET_TEAM_DESCRIPTION';
export const SET_GAME_SERVER_IP = 'team/SET_GAME_SERVER_IP';
export const SET_GAME_SERVER_PW = 'team/SET_GAME_SERVER_PW';
export const SET_GAME_SERVER_RCON = 'team/SET_GAME_SERVER_RCON';
export const SET_RIOT_ID = 'team/SET_RIOT_ID';
export const REQUEST_REMOVE = 'team/REQUEST_REMOVE';
export const REMOVE = 'team/REMOVE';
export const REQUEST_LEAVE_GROUP = 'team/REQUEST_LEAVE_GROUP';
export const LEAVE_GROUP = 'team/LEAVE_GROUP';
export const CHANGE_POSITION = 'team/CHANGE_POSITION';
export const SAVE_TEAM_REQUESTED = 'team/SAVE_TEAM_REQUESTED';
export const SAVE_TEAM = 'team/SAVE_TEAM';
export const SAVE_MEMBERS_REQUESTED = 'team/SAVE_MEMBERS_REQUESTED';
export const SAVE_MEMBERS = 'team/SAVE_MEMBERS';
export const SAVE_GROUPS_REQUESTED = 'team/SAVE_GROUPS_REQUESTED';
export const SAVE_BLOCKLISTED_REQUESTED = 'team/SAVE_BLOCKLISTED_REQUESTED';
export const SAVE_GROUP_BLOCKLISTED_REQUESTED = 'team/SAVE_GROUP_BLOCKLISTED_REQUESTED';
export const SAVE_GROUPS = 'team/SAVE_GROUPS';
export const SAVE_BLOCKLISTED = 'team/SAVE_BLOCKLISTED';
export const LOAD_REQUESTED = 'team/LOAD_REQUESTED';
export const LOAD = 'team/LOAD';
export const TEAM_LEAVE_REQUESTED = 'team/TEAM_LEAVE_REQUESTED';
export const TEAM_LEFT = 'team/TEAM_LEFT';
export const BOT_SPAWN_REQUESTED = 'team/BOT_SPAWN_REQUESTED';
export const BOT_SPAWNED = 'team/BOT_SPAWNED';
export const BOT_STOP_REQUESTED = 'team/BOT_STOP_REQUESTED';
export const BOT_STOPPED = 'team/BOT_STOPPED';
export const SET_REQUEST_PAGE = 'team/SET_REQUEST_PAGE';
export const SET_CALENDAR_LINK = 'team/SET_CALENDAR_LINK';
export const SET_INVITE_LINK = 'team/SET_INVITE_LINK';
export const SET_INVITE_LINK_REQUESTED = 'team/SET_INVITE_LINK_REQUESTED';
export const SET_UPLOADED_IMAGE = 'team/SET_UPLOADED_IMAGE';
export const SET_CROPPED_IMAGE = 'team/SET_CROPPED_IMAGE';
export const REMOVE_IMAGE = 'team/REMOVE_IMAGE';
export const ADD_MEMBER = 'team/ADD_MEMBER';
export const ADD_LINK_REQUESTED = 'team/ADD_LINK_REQUESTED';
export const ADD_LINK_SUCCESS = 'team/ADD_LINK_SUCCESS';
export const ADD_LINK = 'team/ADD_LINK';
export const REMOVE_LINK_SUCCESS = 'team/REMOVE_LINK_SUCCESS';
export const SET_PLATFORM_TYPE = 'team/SET_PLATFORM_TYPE';
export const SET_PLATFORM_URL = 'team/SET_PLATFORM_URL';
export const RESET = 'team/RESET';
export const REQUEST_DELETE_BLOCKEDTEAM = 'team/REQUEST_DELETE_BLOCKEDTEAM';
export const REMOVE_BLOCKEDTEAM = 'team/REMOVE_BLOCKEDTEAM';
export const UPDATE_TAB_GROUP_BLOCK_INDEX = 'team/UPDATE_TAB_GROUP_BLOCK_INDEX';
export const SET_PAUSE = 'team/SET_PAUSE';
export const SET_DM_TECHNICAL = 'team/SET_DM_TECHNICAL';
export const SET_FREEZETIME = 'team/SET_FREEZETIME';
export const SET_WARMUPMODE = 'team/SET_WARMUPMODE';
export const SAVE_SERVER_CUSTOMIZATION_REQUESTED = 'team/SAVE_SERVER_CUSTOMIZATION_REQUESTED';
export const INSTALL_PLUGIN = 'team/INSTALL_SOURCEMOD_PLUGIN';
export const INSTALLED_PLUGIN = 'team/INSTALLED_SOURCEMOD_PLUGIN';
export const SET_TEAM_SOLID_TACTICS = 'team/SET_TEAM_SOLID_TACTICS';
export const SET_DAMAGE_PRINT = 'team/SET_DAMAGE_PRINT';
export const SET_AUTOSWITCH = 'team/SET_AUTOSWITCH';
export const SET_TEAM_SHORT_NAME = 'team/SET_TEAM_SHORT_NAME';
export const SET_BETA_BOT = 'team/SET_BETA_BOT';

const initialState = {
    botLoading: false,
    teamId: null,
    teamName: '',
    teamShortName: '',
    teamDescription: '',
    logoUrl: null,
    croppedImage: null,
    gameServerIp: '',
    verified: 0,
    gameServerPw: '',
    gameServerRcon: '',
    riotId: '',
    inviteId: '',
    members: [],
    groups: [],
    links: [],
    blocklisted: [],
    favorites: [],
    isAddingLink: false,
    platformName: '',
    platformUrl: '',
    tabGroupBLockIndex: 0,
    logs: [],
    freezetime: 20,
    isTechnicalDmEnabled: true,
    isTournamentPauseEnabled: false,
    isInstallingPlugin: false,
    warmupMode: 'dm',
    botRunnig: false,
    isTeamSolidTactics: true,
    isDamagePrint: true,
    isAutoswitch: true,
    isBetaBot: false,
    isBetaTeam: false,
    serverLocation: 'dusseldorf',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_TEAM_NAME:
            return {
                ...state,
                teamName: action.teamName,
            };
        case SET_TEAM_DESCRIPTION:
            return {
                ...state,
                teamDescription: action.teamDescription,
            };
        case SET_GAME_SERVER_IP:
            return {
                ...state,
                gameServerIp: action.gameServerIp,
            };
        case SET_GAME_SERVER_PW:
            return {
                ...state,
                gameServerPw: action.gameServerPw,
            };
        case SET_GAME_SERVER_RCON:
            return {
                ...state,
                gameServerRcon: action.gameServerRcon,
            };
        case SET_RIOT_ID:
            return {
                ...state,
                riotId: action.riotId,
            };
        case REQUEST_REMOVE:
            return {
                ...state,
                members: state.members.map((m) => {
                    if (m.id !== action.id) {
                        return m;
                    }
                    return {
                        ...m,
                        isDeleting: action.isDeleting,
                    };
                }),
            };
        case ADD_MEMBER:
            return {
                ...state,
                members: [...state.members, action.payload],
            };
        case REMOVE:
            return {
                ...state,
                members: state.members.map((m) => {
                    if (m.id !== action.id) {
                        return m;
                    }

                    return {
                        ...m,
                        isDeleted: true,
                        isDeleting: false,
                    };
                }),
            };
        case REQUEST_LEAVE_GROUP:
            return {
                ...state,
                groups: state.groups.map((m) => {
                    if (m.id !== action.id) {
                        return m;
                    }

                    return {
                        ...m,
                        isDeleting: action.isDeleting,
                    };
                }),
            };
        case REQUEST_DELETE_BLOCKEDTEAM:
            return {
                ...state,
                blocklisted: state.blocklisted.map((m) => {
                    if (m.id !== action.id) {
                        return m;
                    }
                    return {
                        ...m,
                        isDeleting: action.isDeleting,
                    };
                }),
            };
        case LEAVE_GROUP:
            return {
                ...state,
                groups: state.groups.map((m) => {
                    if (m.id !== action.id) {
                        return m;
                    }

                    return {
                        ...m,
                        isDeleted: true,
                        isDeleting: false,
                    };
                }),
            };
        case REMOVE_BLOCKEDTEAM:
            return {
                ...state,
                blocklisted: state.blocklisted.map((m) => {
                    if (m.id !== action.id) {
                        return m;
                    }
                    return {
                        ...m,
                        isDeleted: true,
                        isDeleting: false,
                    };
                }),
            };
        case CHANGE_POSITION:
            return {
                ...state,
                members: state.members.map((m) => {
                    if (m.id !== action.id) {
                        return m;
                    }

                    return {
                        ...m,
                        position: action.position,
                    };
                }),
            };

        case SAVE_TEAM_REQUESTED:
            return {
                ...state,
                isSavingTeam: true,
            };
        case SAVE_SERVER_CUSTOMIZATION_REQUESTED:
            return {
                ...state,
                isSavingCustomization: true,
            };
        case INSTALL_PLUGIN:
            return {
                ...state,
                isInstallingPlugin: true,
            };
        case INSTALLED_PLUGIN:
            return {
                ...state,
                isInstallingPlugin: false,
            };
        case SAVE_TEAM:
            return {
                ...state,
                isSavingTeam: false,
            };
        case SAVE_GROUPS_REQUESTED:
            return {
                ...state,
                isSavingGroups: true,
            };
        case SAVE_GROUPS:
            return {
                ...state,
                isSavingGroups: false,
                groups: action.groups.map((g) => ({
                    id: g.ID,
                    name: g.Name,
                    logo: g.Logo,
                })),
            };
        case SAVE_BLOCKLISTED_REQUESTED:
            return {
                ...state,
                isSavingBlocklisted: true,
            };
        case SAVE_GROUP_BLOCKLISTED_REQUESTED:
            return {
                ...state,
                isSavingGroupAndBlocklisted: true,
            };
        case SAVE_BLOCKLISTED:
            return {
                ...state,
                isSavingGroupAndBlocklisted: false,
                blocklisted: action.blocklisted.map((b) => ({
                    id: b.ID,
                    blockedteamId: b.BlockedTeam,
                    name: b.Name,
                    comment: b.Comment,
                })),
            };
        case SAVE_MEMBERS_REQUESTED:
            return {
                ...state,
                isSavingMembers: true,
            };
        case SAVE_MEMBERS:
            return {
                ...state,
                isSavingMembers: false,
            };
        case LOAD_REQUESTED:
            return {
                ...state,
                isLoading: true,
            };
        case TEAM_LEAVE_REQUESTED:
            return {
                ...state,
                isLoading: true,
            };
        case TEAM_LEFT:
            return {
                ...state,
                isLoading: false,
            };
        case SET_REQUEST_PAGE:
            return {
                ...state,
                externalLinkId: action.externalLinkId,
            };
        case SET_CALENDAR_LINK:
            return {
                ...state,
                calendarLinkId: action.calendarLinkId,
            };
        case SET_INVITE_LINK_REQUESTED:
            return {
                ...state,
                isSavingMembers: true,
            };
        case SET_INVITE_LINK:
            return {
                ...state,
                isSavingMembers: false,
                inviteId: action.inviteId,
            };
        case BOT_SPAWN_REQUESTED:
            return {
                ...state,
                botLoading: true,
            };
        case BOT_SPAWNED:
            return {
                ...state,
                botLoading: false,
                botRunnig: action.payload.BotRunning,
            };
        case BOT_STOP_REQUESTED:
            return {
                ...state,
                botLoading: true,
            };
        case BOT_STOPPED:
            return {
                ...state,
                botLoading: false,
                botRunnig: action.payload.BotRunning,
            };
        case LOAD:
            return {
                ...state,
                isLoading: false,
                isSavingTeam: false,
                isSavingCustomization: false,
                teamId: action.payload.ID,
                teamName: action.payload.Name,
                teamShortName: action.payload.ShortName,
                teamDescription: action.payload.Description,
                gameServerIp: action.payload.GameServer,
                gameServerPw: action.payload.Password,
                gameServerRcon: action.payload.Rcon,
                riotId: action.payload.RiotID,
                verified: action.payload.Verified,
                inviteId: action.payload.InviteId,
                description: action.payload.Description,
                logoUrl: action.payload.Logo,
                gameId: action.payload.GameID,
                externalLinkId: action.payload.ExternalLinkId,
                calendarLinkId: action.payload.CalendarLinkId,
                freezetime: action.payload.ServerConfig.Freezetime,
                isTechnicalDmEnabled: action.payload.ServerConfig.IsTechnicalDmEnabled,
                isTournamentPauseEnabled: action.payload.ServerConfig.IsTournamentPauseEnabled,
                warmupMode: action.payload.ServerConfig.WarmupMode,
                botRunnig: action.payload.BotRunning,
                isTeamSolidTactics: action.payload.ServerConfig.IsTeamSolidTactics,
                isDamagePrint: action.payload.ServerConfig.IsDamagePrint,
                isAutoswitch: action.payload.ServerConfig.IsAutoswitch,
                isBetaBot: action.payload.ServerConfig.IsBetaBot,
                serverLocation: action.payload.ServerConfig.Location,
                members: action.payload.Members,
                groups: action.payload.Groups
                    ? action.payload.Groups.map((g) => ({
                          id: g.ID,
                          name: g.Name,
                          logo: g.Logo,
                          createdAt: g.CreatedAt,
                      }))
                    : [],
                links: action.payload.Links
                    ? action.payload.Links.map((e) => ({
                          url: e.Url,
                          type: e.Type,
                          ResponseJsonApi: e.ResponseJsonApi,
                      }))
                    : [],
                blocklisted: action.payload.Blocklisted
                    ? action.payload.Blocklisted.map((b) => ({
                          id: b.ID,
                          blockedteamId: b.BlockedTeamID,
                          name: b.Name,
                          logo: b.Logo,
                          createdAt: b.CreatedAt,
                          note: b.Note,
                      }))
                    : [],
                favorites: action.payload.Favorites ? action.payload.Favorites : [],
                logs: action.payload.Logs ? action.payload.Logs : [],
            };
        case RESET:
            return initialState;

        case SET_UPLOADED_IMAGE:
            return {
                ...state,
                uploadedImage: action.image,
            };
        case SET_CROPPED_IMAGE:
            return {
                ...state,
                croppedImage: action.image,
            };
        case REMOVE_IMAGE:
            return {
                ...state,
                logoUrl: '',
                croppedImage: '',
            };

        case ADD_LINK_REQUESTED:
            return {
                ...state,
                isAddingLink: true,
            };

        case ADD_LINK:
            return {
                ...state,
                isAddingLink: false,
            };

        case ADD_LINK_SUCCESS:
            const newLinks = state.links;
            newLinks.push({
                type: action.platformType,
                url: action.platformUrl,
            });

            return {
                ...state,
                platformName: '',
                platformUrl: '',
                links: newLinks,
            };

        case REMOVE_LINK_SUCCESS:
            return {
                ...state,
                links: state.links.filter((l) => l.type !== action.platformType),
            };

        case SET_PLATFORM_URL:
            return {
                ...state,
                platformUrl: action.platformUrl,
            };
        case UPDATE_TAB_GROUP_BLOCK_INDEX:
            return {
                ...state,
                tabGroupBLockIndex: action.tabGroupBLockIndex,
            };
        case SET_PLATFORM_TYPE:
            return {
                ...state,
                platformName: action.platformType,
            };
        case SET_DM_TECHNICAL:
            return {
                ...state,
                isTechnicalDmEnabled: action.checkedDmTechnical,
            };
        case SET_FREEZETIME:
            return {
                ...state,
                freezetime: action.freezetime,
            };
        case SET_PAUSE:
            return {
                ...state,
                isTournamentPauseEnabled: action.isTournamentPause,
            };
        case SET_WARMUPMODE:
            return {
                ...state,
                warmupMode: action.warmupMode,
            };
        case SET_TEAM_SOLID_TACTICS:
            return {
                ...state,
                isTeamSolidTactics: action.isTeamSolidTactics,
            };
        case SET_DAMAGE_PRINT:
            return {
                ...state,
                isDamagePrint: action.isDamagePrint,
            };
        case SET_AUTOSWITCH:
            return {
                ...state,
                isAutoswitch: action.isAutoswitch,
            };
        case SET_BETA_BOT:
            return {
                ...state,
                isBetaBot: action.isBetaBot,
            };
        case SET_TEAM_SHORT_NAME:
            return {
                ...state,
                teamShortName: action.teamShortName,
            };
        default:
            return state;
    }
};

export const setTeamName = (teamName) => {
    return (dispatch) => {
        dispatch({
            type: SET_TEAM_NAME,
            teamName,
        });
    };
};

export const setTeamShortName = (teamShortName) => {
    return (dispatch) => {
        dispatch({
            type: SET_TEAM_SHORT_NAME,
            teamShortName,
        });
    };
};

export const setTeamDescription = (teamDescription) => {
    return (dispatch) => {
        dispatch({
            type: SET_TEAM_DESCRIPTION,
            teamDescription,
        });
    };
};

export const setGameServerIp = (gameServerIp) => {
    return (dispatch) => {
        dispatch({
            type: SET_GAME_SERVER_IP,
            gameServerIp,
        });
    };
};

export const setGameServerPw = (gameServerPw) => {
    return (dispatch) => {
        dispatch({
            type: SET_GAME_SERVER_PW,
            gameServerPw,
        });
    };
};

export const setGameServerRcon = (gameServerRcon) => {
    return (dispatch) => {
        dispatch({
            type: SET_GAME_SERVER_RCON,
            gameServerRcon,
        });
    };
};

export const setRiotId = (riotId) => {
    return (dispatch) => {
        dispatch({
            type: SET_RIOT_ID,
            riotId,
        });
    };
};

export const requestRemove = (id) => {
    return (dispatch) => {
        dispatch({
            type: REQUEST_REMOVE,
            isDeleting: true,
            id,
        });
    };
};

export const remove = (id) => {
    return (dispatch) => {
        dispatch({
            type: REMOVE,
            id,
        });
    };
};

export const keep = (id) => {
    return (dispatch) => {
        dispatch({
            type: REQUEST_REMOVE,
            isDeleting: false,
            id,
        });
    };
};

export const requestLeaveGroup = (id) => {
    return (dispatch) => {
        dispatch({
            type: REQUEST_LEAVE_GROUP,
            isDeleting: true,
            id,
        });
    };
};

export const leaveGroup = (id) => {
    return (dispatch) => {
        dispatch({
            type: LEAVE_GROUP,
            id,
        });
    };
};

export const stayInGroup = (id) => {
    return (dispatch) => {
        dispatch({
            type: REQUEST_LEAVE_GROUP,
            isLeaving: false,
            id,
        });
    };
};

export const requestDeleteBlockedTeam = (id) => {
    return (dispatch) => {
        dispatch({
            type: REQUEST_DELETE_BLOCKEDTEAM,
            isDeleting: true,
            id,
        });
    };
};

export const changePosition = (members, id, position) => {
    return async (dispatch) => {
        let teamOwner = null;
        let teamOwner1 = null;
        members.forEach(function (member) {
            if (member.Position === 'Owner') {
                if (teamOwner === null) {
                    teamOwner = member;
                } else {
                    teamOwner1 = member;
                }
            }
        });
        if (teamOwner === null) {
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'An owner must be set in the team.',
                variant: 'error',
            });
            return;
        }
        if (teamOwner1 !== null && (teamOwner.ID === id || teamOwner1.ID === id) && position === 'Owner') {
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'There can only be one owner on the Team.',
                variant: 'error',
            });
            return;
        }
        if (teamOwner !== null && teamOwner1 === null && teamOwner.ID === id && position !== 'Owner') {
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'An owner must be set in the team.',
                variant: 'error',
            });
            return;
        }
        dispatch({
            type: SAVE_TEAM_REQUESTED,
        });

        try {
            const res = await axios.post('/api/team/changeposition', {
                SteamAccountId: id,
                Position: position,
            });
            dispatch({
                type: LOAD,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Saved!',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        } finally {
            dispatch({
                type: SAVE_TEAM,
            });
        }
    };
};

export const saveTeam = (team) => {
    return async (dispatch) => {
        dispatch({
            type: SAVE_TEAM_REQUESTED,
        });

        try {
            const res = await axios.post('/api/team', {
                TeamName: team.teamName,
                Description: team.teamDescription,
                GameServer: team.gameServerIp,
                Password: team.gameServerPw,
                RiotID: team.riotId,
                CroppedImage: team.croppedImage,
                LogoUrl: team.logoUrl,
                ShortName: team.teamShortName,
            });
            dispatch({
                type: LOAD,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Saved!',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        } finally {
            dispatch({
                type: SAVE_TEAM,
            });
        }
    };
};

export const updateTeam = (team) => {
    return async (dispatch) => {
        dispatch({
            type: SAVE_TEAM_REQUESTED,
        });

        try {
            const res = await axios.post('/api/team/teamupdate', {
                TeamName: team.teamName,
                Description: team.teamDescription,
                CroppedImage: team.croppedImage,
                LogoUrl: team.logoUrl,
                RiotID: team.riotId,
                ShortName: team.teamShortName,
            });
            dispatch({
                type: LOAD,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Saved!',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        } finally {
            dispatch({
                type: SAVE_TEAM,
            });
        }
    };
};

export const saveMembers = (members, memberKick) => {
    return async (dispatch) => {
        let teamOwner = null;
        let teamOwner1 = null;
        members.forEach(function (member) {
            if (member.Position === 'Owner') {
                if (teamOwner === null) {
                    teamOwner = member;
                } else {
                    teamOwner1 = member;
                }
            }
        });
        if (teamOwner === null) {
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'An owner must be set in the team.',
                variant: 'error',
            });
            return;
        }
        if (teamOwner1 !== null) {
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'There can only be one owner on the Team.',
                variant: 'error',
            });
            return;
        }
        dispatch({
            type: SAVE_MEMBERS_REQUESTED,
        });

        try {
            const res = await axios.post('/api/team/member', {
                SteamAccountID: memberKick.ID,
                Position: memberKick.Position,
                IsDeleted: true,
            });
            dispatch({
                type: LOAD,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Saved!',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        } finally {
            dispatch({
                type: SAVE_MEMBERS,
            });
        }
    };
};

export const saveGroup = (groupId) => {
    return async (dispatch) => {
        dispatch({
            type: SAVE_GROUPS_REQUESTED,
        });

        try {
            const res = await axios.post('/api/team/leave-groups', groupId);
            dispatch({
                type: SAVE_GROUPS,
                groups: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Saved!',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const reset = () => {
    return (dispatch) => dispatch({ type: RESET });
};

export const load = () => {
    return async (dispatch) => {
        dispatch({
            type: LOAD_REQUESTED,
        });

        try {
            const res = await axios.get('/api/team');
            dispatch({
                type: LOAD,
                payload: res.data,
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const loadTeamView = (id) => {
    return async (dispatch) => {
        dispatch({
            type: LOAD_REQUESTED,
        });

        try {
            const res = await axios.get('/api/team/view/' + id);
            dispatch({
                type: LOAD,
                payload: res.data,
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const generateNewCalendarLink = () => {
    return async (dispatch) => {
        try {
            const res = await axios.post('/api/team/generate-calendar-link');
            dispatch({
                type: SET_CALENDAR_LINK,
                calendarLinkId: res.data.CalendarLinkId,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Calendar Link has been updated!',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const generateNewPageLink = () => {
    return async (dispatch) => {
        try {
            const res = await axios.post('/api/team/generate-external-link');
            dispatch({
                type: SET_REQUEST_PAGE,
                externalLinkId: res.data.ExternalLinkId,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Request Page Link has been updated!',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const generateNewInviteLink = () => {
    return async (dispatch) => {
        dispatch({
            type: SET_INVITE_LINK_REQUESTED,
        });

        try {
            const res = await axios.post('/api/team/generate-invite-link');
            dispatch({
                type: SET_INVITE_LINK,
                inviteId: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Generated new invite Link!',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const setUploadedImage = (e) => {
    return (dispatch) => {
        e.preventDefault();
        const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        const reader = new FileReader();
        reader.onload = () => {
            dispatch({
                type: SET_UPLOADED_IMAGE,
                image: reader.result,
            });
        };
        reader.readAsDataURL(files[0]);
        e.target.value = '';
    };
};

export const confirmUploadedImage = (image) => {
    return (dispatch) => {
        dispatch({
            type: SET_CROPPED_IMAGE,
            image,
        });
    };
};

export const removeUploadedImage = () => {
    return (dispatch) => {
        dispatch({
            type: SET_UPLOADED_IMAGE,
            image: null,
        });
    };
};

export const removeImage = () => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_IMAGE,
        });
    };
};

export const addMember = (payload) => {
    return (dispatch) => {
        dispatch({
            type: ADD_MEMBER,
            payload,
        });
    };
};

export const spawnBot = () => {
    return async (dispatch) => {
        dispatch({
            type: BOT_SPAWN_REQUESTED,
        });

        try {
            const res = await axios.post('/api/bot/spawn');
            dispatch({
                type: BOT_SPAWNED,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Bot spawned successfully.',
                variant: 'info',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const stopBot = () => {
    return async (dispatch) => {
        dispatch({
            type: BOT_STOP_REQUESTED,
        });

        try {
            const res = await axios.post('/api/bot/stop');
            dispatch({
                type: BOT_STOPPED,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Bot stopped successfully.',
                variant: 'info',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const leaveTeam = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: TEAM_LEAVE_REQUESTED,
        });

        try {
            const teamId = getState().app.profile.Team.ID;
            await axios.post('/api/team/leave');
            dispatch({
                type: TEAM_LEFT,
            });
            dispatch({
                type: REMOVE_TEAM_PROFILE,
                teamId: teamId,
            });
        } catch (e) {
            handleError(e)(dispatch);
            dispatch({
                type: TEAM_LEFT,
            });
        }
    };
};

export const addLink = (type, url) => {
    return async (dispatch) => {
        dispatch({
            type: ADD_LINK_REQUESTED,
        });

        try {
            await axios.post('/api/team/links', {
                Type: type,
                Url: url,
            });

            dispatch({
                type: ADD_LINK_SUCCESS,
                platformType: type,
                platformUrl: url,
            });

            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Link added successfully!',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        } finally {
            dispatch({
                type: ADD_LINK,
            });
        }
    };
};

export const removeLink = (type) => {
    return async (dispatch) => {
        try {
            await axios.delete('/api/team/links', {
                data: {
                    Type: type,
                },
            });

            dispatch({
                type: REMOVE_LINK_SUCCESS,
                platformType: type,
            });

            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Link removed successfully!',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const setPlatformType = (platformType) => {
    return (dispatch) => {
        dispatch({
            type: SET_PLATFORM_TYPE,
            platformType: platformType,
        });
    };
};

export const setPlatformUrl = (platformUrl) => {
    return (dispatch) => {
        dispatch({
            type: SET_PLATFORM_URL,
            platformUrl: platformUrl,
        });
    };
};

export const updateTabGroupBLockIndex = (event, newValue) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_TAB_GROUP_BLOCK_INDEX,
            tabGroupBLockIndex: newValue,
        });
    };
};

export const removeBlockedTeamFromTeam = (teamID) => {
    return async (dispatch) => {
        dispatch({
            type: SAVE_TEAM_REQUESTED,
        });
        try {
            const res = await axios.delete('/api/team/deleteblacklist', {
                data: {
                    TeamID: teamID,
                },
            });
            dispatch({
                type: LOAD,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Team block removed',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const updateServer = (gameServerIp, gameServerPw, gameServerRcon) => {
    return async (dispatch) => {
        dispatch({
            type: SAVE_TEAM_REQUESTED,
        });
        try {
            const res = await axios.post('/api/team/updateserver', {
                ServerIP: gameServerIp,
                Password: gameServerPw,
                Rcon: gameServerRcon,
            });
            dispatch({
                type: LOAD,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Server has been updated',
                variant: 'success',
            });
            return true;
        } catch (e) {
            console.log(e.response.data.Code);
            if (e.response.data.Code === 'invalid_gameserver') {
                dispatch({
                    type: QUEUE_MESSAGE,
                    msg: e.response.data.Message,
                    variant: 'error',
                    code: e.response.data.Code,
                });
                return true;
            } else {
                handleError(e)(dispatch);
                return false;
            }
        } finally {
            dispatch({
                type: SAVE_TEAM,
            });
        }
    };
};

export const updateDmTechnical = (checkedDmTechnical) => {
    return (dispatch) => {
        dispatch({
            type: SET_DM_TECHNICAL,
            checkedDmTechnical,
        });
    };
};

export const updateFreezetime = (freezetime) => {
    return (dispatch) => {
        dispatch({
            type: SET_FREEZETIME,
            freezetime,
        });
    };
};

export const updateIsTournamentPauseEnabled = (isTournamentPause) => {
    return (dispatch) => {
        dispatch({
            type: SET_PAUSE,
            isTournamentPause,
        });
    };
};

export const updateWarmupMode = (warmupMode) => {
    return (dispatch) => {
        dispatch({
            type: SET_WARMUPMODE,
            warmupMode,
        });
    };
};

export const updateIsTeamSolidTactics = (isTeamSolidTactics) => {
    return (dispatch) => {
        dispatch({
            type: SET_TEAM_SOLID_TACTICS,
            isTeamSolidTactics,
        });
    };
};

export const updateIsDamagePrint = (isDamagePrint) => {
    return (dispatch) => {
        dispatch({
            type: SET_DAMAGE_PRINT,
            isDamagePrint,
        });
    };
};

export const updateIsAutoswitch = (isAutoswitch) => {
    return (dispatch) => {
        dispatch({
            type: SET_AUTOSWITCH,
            isAutoswitch,
        });
    };
};

export const updateIsBetaBot = (isBetaBot) => {
    return (dispatch) => {
        dispatch({
            type: SET_BETA_BOT,
            isBetaBot,
        });
    };
};

export const updateServerConfig = (
    freezetime,
    isTechnicalDmEnabled,
    isTournamentPauseEnabled,
    isDamagePrint,
    isTeamSolidTactics,
    isAutoswitch,
    isBetaBot
) => {
    return async (dispatch) => {
        dispatch({
            type: SAVE_SERVER_CUSTOMIZATION_REQUESTED,
        });
        try {
            const res = await axios.post('/api/team/updatecustomize', {
                Freezetime: freezetime,
                IsTechnicalDmEnabled: isTechnicalDmEnabled,
                IsTournamentPauseEnabled: isTournamentPauseEnabled,
                IsDamagePrint: isDamagePrint,
                IsTeamSolidTactics: isTeamSolidTactics,
                IsAutoswitch: isAutoswitch,
                IsBetaBot: isBetaBot,
            });
            dispatch({
                type: LOAD,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'PRACC.COM BOT customization has been updated',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        }
    };
};

export const installPlugin = (ftpIp, ftpUser, ftpPassword, ftpSystem, pluginType) => {
    return async (dispatch) => {
        dispatch({
            type: INSTALL_PLUGIN,
        });
        try {
            await axios.post('/api/team/uploadplugin', {
                IP: ftpIp,
                User: ftpUser,
                Password: ftpPassword,
                System: ftpSystem,
                PluginType: pluginType,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                variant: 'success',
                msg: 'PRACC.COM ' + pluginType.toUpperCase() + '-Plugin has been installed',
            });
            dispatch({
                type: INSTALLED_PLUGIN,
            });

            return true;
        } catch (e) {
            handleError(e)(dispatch);
            dispatch({
                type: INSTALLED_PLUGIN,
            });

            return false;
        }
    };
};

export const verifyGameServer = (gameServerIp, gameServerPw, gameServerRcon, newTeam = false) => {
    return async (dispatch) => {
        dispatch({
            type: SAVE_TEAM_REQUESTED,
        });
        try {
            const res = await axios.post('/api/team/verifyserver', {
                ServerIP: gameServerIp,
                Password: gameServerPw,
                Rcon: gameServerRcon,
                NewTeam: newTeam,
            });
            dispatch({
                type: LOAD,
                payload: res.data,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Server has been updated',
                variant: 'success',
            });
            return true;
        } catch (e) {
            handleError(e)(dispatch);
            return false;
        } finally {
            dispatch({
                type: SAVE_TEAM,
            });
        }
    };
};
