import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import { Link as RouterLink } from 'react-router-dom';
import { confirmDeleteMatch } from './reducer';
import { addMessages, subscribe, unsubscribe } from './reducerChat';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import icon from 'components/icon';
import moment from 'moment-timezone';
import ChatPanel from './chatPanel';
import InputAdornment from '@mui/material/InputAdornment';
import { getMapFromNumber, getMapsForGameId } from '../../utils/maps';
import {
    GAME_ID_CSGO,
    GAME_ID_LOL,
    GAME_ID_LOL_WILD_RIFT,
    GAME_ID_VALORANT,
    getGameForProfile,
} from '../../utils/games';
import { Box, Typography } from '@mui/material';
import { resolveFullTimeFormat, resolveHourTimeFormat } from '../../utils/i18n';
import { browserHistory } from '../../store';
import CopyToClipboardButton from '../../components/CopyToClipboardButton';
import PraccPaperHeader from '../../components/paper/PraccPaperHeader';
import BlockTeamButton from '../../components/team/BlockTeamButton';
import BoostGate from '../../components/boost/BoostGate';
import Avatar from '@mui/material/Avatar';
import FavoriteTeamButton from '../../components/team/FavoriteTeamButton';
import axios from 'axios';
import { handleError, queueMessage } from '../../components/snackbar/reducer';

const TeamImage = styled(Avatar)`
    width: 60px;
    height: 60px;
    margin-right: 20px;
`;

export const TeamName = styled(Link)`
    font-weight: 300;
    font-size: 20px;
`;

const MapImage = styled.div`
    background-image: url(${(props) => props.src});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    color: white;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 20px;

    @media only screen and (min-width: 768px) {
        flex-direction: row;
    }
`;

const InfoPanel = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 0px;
    flex-shrink: 0;
    background-color: #181D31;

    @media only screen and (min-width: 768px) {
        width: 240px;
    }
`;

const ActionButton = styled(Button)`
    margin-top: 20px;
`;

const NoGameserverText = styled.span`
    padding: 1px;
`;

const Darken = styled.div`
    background: rgba(0, 0, 0, 0.8);
    padding: 10px;
`;

const LabelValueContainer = styled.div`
    display: flex;
    flex-direction: row;
    color: white;
    align-items: center;
    padding: 10px;
    font-size: 15px;
`;

const Label = styled.span`
    width: 70px;
    white-space: nowrap;
`;

const Value = styled.span`
    font-weight: 200;
`;

const Actions = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

class LabelValue extends Component {
    render() {
        return (
            <LabelValueContainer style={{ ...this.props.style }}>
                <Label>{`${this.props.label}:`}</Label>
                <Value>{this.props.value}</Value>
            </LabelValueContainer>
        );
    }
}

const DeleteQuestion = styled.div`
    text-align: center;
    font-size: 15px;
`;

class Match extends Component {
    state = {
        isSaving: false,

        isCancelling: false,
        cancelReason: null,
        otherMessage: '',
    };

    componentDidMount() {
        const matchId = this.props.match.ID;
        this.props.subscribe(matchId);
    }

    actions = () => {
        return (
            <Actions>
                {this.renderCopyGameServerAction()}
                <StartServerMatchOnDemand
                    teamLower={this.props.match.TeamLower}
                    teamHigher={this.props.match.TeamHigher}
                    matchTime={this.props.match.Time}
                />
                {this.renderCancelAction()}
                {this.renderRateOpponent()}
                {this.renderBlockTeam()}
                {this.renderFavoriteTeam()}
            </Actions>
        );
    };

    renderCancelAction() {
        const { match, timezone, profile } = this.props;
        if (match.IsCupMatch) {
            return null;
        }
        if (match.Cancelled) {
            let cancellingTeam;
            if (match.CancellingTeamID === match.TeamLower.ID) {
                cancellingTeam = match.TeamLower;
            } else if (match.CancellingTeamID === match.TeamHigher.ID) {
                cancellingTeam = match.TeamHigher;
            }

            let reason = match.CancelReasonMessage || '';
            if (match.CancelReason === 'no_show') {
                reason = 'Opponent did not show up';
            } else if (match.CancelReason === 'plan_changed') {
                reason = 'Plans changed';
            }

            if (cancellingTeam) {
                return (
                    <div style={{ marginTop: '20px' }}>
                        <p>
                            Cancelled at:{' '}
                            <span>
                                {moment(match.CancelledAt)
                                    .tz(timezone)
                                    .format(resolveFullTimeFormat(profile.Settings.TimeFormat))}
                            </span>
                        </p>
                        Match was cancelled by team <span style={{ fontWeight: 'bold' }}>{cancellingTeam.Name}</span>.
                        Reason: <span style={{ fontStyle: 'italic' }}>{reason}</span>
                    </div>
                );
            }

            return (
                <div style={{ marginTop: '20px' }}>
                    <p>
                        Cancelled at:{' '}
                        <span>
                            {moment(match.CancelledAt)
                                .tz(timezone)
                                .format(resolveFullTimeFormat(profile.Settings.TimeFormat))}
                        </span>
                    </p>
                    This match has been cancelled by <span style={{ fontWeight: 'bold' }}>site admin</span>. Reason:{' '}
                    <span style={{ fontStyle: 'italic' }}>{reason}</span>
                </div>
            );
        }

        return (
            <ActionButton
                icon={icon('delete', 'white')}
                onClick={() => this.setState({ isCancelling: true })}
                color="secondary"
                label="cancel and delete"
            >
                Cancel Match
            </ActionButton>
        );
    }

    renderRateOpponent() {
        const { match } = this.props;
        if (match.IsCupMatch) {
            return null;
        }
        return (
            <Button href={'/rating/rate'} color={'primary'} variant={'text'}>
                Rate your Opponent
            </Button>
        );
    }

    renderBlockTeam() {
        const { profile, match } = this.props;
        return (
            <BlockTeamButton
                fullWidth
                buttonSx={{ marginTop: 1 }}
                team={profile.Team.ID !== match.TeamHigher.ID ? match.TeamHigher : match.TeamLower}
            />
        );
    }

    renderFavoriteTeam() {
        const { profile, match } = this.props;
        return (
            <FavoriteTeamButton
                buttonSx={{ marginTop: 2 }}
                fullWidth
                isButton
                team={profile.Team.ID !== match.TeamHigher.ID ? match.TeamHigher : match.TeamLower}
            />
        );
    }

    renderCopyGameServerAction() {
        const { profile, match } = this.props;
        const game = getGameForProfile(profile);
        if (game.id === GAME_ID_CSGO) {
            if (!match.GameServer) {
                return (
                    <NoGameserverText>
                        No game server decided yet, please use the match chat to determine the server to use.
                    </NoGameserverText>
                );
            }

            const connectStr =
                match.GameServer.IP === ''
                    ? 'coming soon'
                    : `connect ${match.GameServer.IP}; password ${match.GameServer.Password}`;

            return (
                <TextField
                    variant="standard"
                    label="IP and Password"
                    fullWidth
                    value={connectStr}
                    margin="dense"
                    InputProps={{
                        endAdornment: match.GameServer.IP !== '' && (
                            <InputAdornment position="end">
                                <CopyToClipboardButton text={connectStr} />
                            </InputAdornment>
                        ),
                    }}
                />
            );
        } else if (game.id === GAME_ID_LOL || game.id === GAME_ID_LOL_WILD_RIFT || game.id === GAME_ID_VALORANT) {
            var connectStr = ``;
            if (profile.Team.ID === match.TeamHigher.ID) {
                if (match.TeamLower.RiotID === '') {
                    return (
                        <NoGameserverText>
                            Your enemy has not entered contact information, please use the match chat to determine the
                            user to add.
                        </NoGameserverText>
                    );
                } else {
                    connectStr = `${match.TeamLower.RiotID}`;
                }
            } else {
                if (match.TeamHigher.RiotID === '') {
                    return (
                        <NoGameserverText>
                            Your enemy has not entered contact information, please use the match chat to determine the
                            user to add.
                        </NoGameserverText>
                    );
                } else {
                    connectStr = `${match.TeamHigher.RiotID}`;
                }
            }

            return (
                <TextField
                    variant="standard"
                    label="Contact"
                    fullWidth
                    value={connectStr}
                    margin="dense"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <CopyToClipboardButton text={connectStr} />
                            </InputAdornment>
                        ),
                    }}
                />
            );
        }
        return null;
    }

    deleteActions = () => {
        return (
            <div style={{ marginTop: '20px' }}>
                <DeleteQuestion>Why do you want to cancel this match?</DeleteQuestion>

                {
                    <TextField
                        value={this.state.otherMessage}
                        placeholder="State your reasons here"
                        variant="outlined"
                        onChange={(e) => this.setState({ otherMessage: e.target.value })}
                        multiline
                        rows={3}
                    />
                }

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <ActionButton
                        icon={icon('delete_forever', 'white')}
                        onClick={() => {
                            this.setState({ cancelReason: 'other' });
                            this.deleteMatch();
                        }}
                        color="secondary"
                        label="delete"
                        disabled={this.state.isSaving}
                    >
                        Cancel Match
                    </ActionButton>
                    <ActionButton
                        icon={icon('arrow_back', 'white')}
                        onClick={() => this.setState({ isCancelling: false })}
                        color="primary"
                        label="keep match"
                        disabled={this.state.isSaving}
                    >
                        Keep
                    </ActionButton>
                </div>
            </div>
        );
    };

    async deleteMatch() {
        this.setState({
            isSaving: true,
        });

        try {
            const { match } = this.props;
            const success = await this.props.confirmDeleteMatch(
                match.ID,
                this.state.cancelReason,
                this.state.otherMessage
            );
            if (success) {
                browserHistory.push('/matches');
            }
        } finally {
            this.setState({
                isSaving: false,
            });
        }
    }

    render = () => {
        const { timezone, profile, match, isModal } = this.props;

        const game = getGameForProfile(profile);
        const maps = getMapsForGameId(game.id);

        function MatchInfo() {
            if (maps.length > 0) {
                const map = getMapFromNumber(game.id, match.Map);

                return <LabelValue label="Map" value={map.label} />;
            }

            return <LabelValue label="Games" value={match.GamesCount} />;
        }

        function ServerInfo() {
            const game = getGameForProfile(profile);
            if (game.id === GAME_ID_CSGO && match.GameServer !== null) {
                const server =
                    match.GameServer.Provider.length > 13
                        ? match.GameServer.Provider.substring(0, 13) + '\u2026'
                        : match.GameServer.Provider;
                return (
                    <BoostGate type="team" featureId="server_priority">
                        <LabelValue label="Server" value={server} style={{ cursor: 'pointer' }} />
                    </BoostGate>
                );
            }
            return null;
        }

        let mapImage = getMapFromNumber(game.id, match.Map).image;
        if (!mapImage) {
            mapImage = game.defaultMapImage;
        }

        return (
            <React.Fragment>
                {this.renderTitle()}

                <Content>
                    <InfoPanel>
                        <MapImage src={mapImage}>
                            <Darken>
                                <MatchInfo />

                                <LabelValue
                                    label="Date"
                                    value={moment(match.Time).tz(timezone).format('MMMM Do YYYY')}
                                />
                                <LabelValue
                                    label="Time"
                                    value={moment(match.Time)
                                        .tz(timezone)
                                        .format(resolveHourTimeFormat(profile.Settings.TimeFormat))}
                                />

                                <ServerInfo />
                            </Darken>
                        </MapImage>
                        {this.state.isCancelling ? this.deleteActions() : this.actions()}
                        {this.renderDiscordInfo()}
                    </InfoPanel>
                    <ChatPanel matchTime={match.Time} matchId={match.ID} />
                </Content>

                {isModal && (
                    <IconButton
                        style={{
                            width: '60px',
                            height: '60px',
                            padding: '10px',
                            position: 'absolute',
                            right: '5px',
                            top: '5px',
                        }}
                        iconStyle={{
                            fontSize: '40px',
                        }}
                        onClick={() => {
                            browserHistory.push('/matches');
                        }}
                        size="large"
                    >
                        <Icon className="material-icons" color="black">
                            close
                        </Icon>
                    </IconButton>
                )}
            </React.Fragment>
        );
    };

    renderDiscordInfo() {
        const { match } = this.props;

        if (match.TeamHigher.GameID !== GAME_ID_LOL) {
            return null;
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '30px' }}>
                <Typography variant="subtitle2">Report Opponent Team:</Typography>
                <a href="https://discordapp.com/invite/2AQaghw" target="_blank">
                    <img
                        height={100}
                        width={102.08}
                        src={require('../../components/assets/platforms/discord.png')}
                        alt="Discord Logo"
                    />
                </a>
            </div>
        );
    }

    renderTitle() {
        const { match, profile } = this.props;

        let otherTeam;
        if (profile.Team.ID === match.TeamLower.ID) {
            otherTeam = match.TeamHigher;
        } else if (profile.Team.ID === match.TeamHigher.ID) {
            otherTeam = match.TeamLower;
        }

        if (!otherTeam) {
            return (
                <PraccPaperHeader
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <TeamImage src={match.TeamLower.Logo} variant={'square'} />
                        <TeamName component={RouterLink} to={'/team/' + match.TeamLower.ID}>
                            {match.TeamLower.Name}
                        </TeamName>
                    </Box>

                    <div>vs.</div>

                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <TeamImage src={match.TeamHigher.Logo} variant={'square'} />
                        <TeamName component={RouterLink} to={'/team/' + match.TeamHigher.ID}>
                            {match.TeamHigher.Name}
                        </TeamName>
                    </Box>
                </PraccPaperHeader>
            );
        }
        return (
            <PraccPaperHeader sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <TeamImage src={otherTeam.Logo} variant={'square'} />
                <TeamName component={RouterLink} to={'/team/' + otherTeam.ID}>
                    {otherTeam.Name}
                </TeamName>
            </PraccPaperHeader>
        );
    }
}

function StartServerMatchOnDemand(props) {
    const { teamLower, teamHigher, matchTime } = props;
    const [isStartServer, setStartServer] = React.useState(false);
    const dispatch = useDispatch();
    if (teamLower.GameID !== GAME_ID_CSGO || teamHigher.GameID !== GAME_ID_CSGO) {
        return null;
    }
    if (teamLower.HasScrimServer || teamHigher.HasScrimServer) {
        return null;
    }
    if (teamLower.IsOnDemandServer || teamHigher.IsOnDemandServer) {
        return (
            <>
                <Button
                    variant="contained"
                    color="success"
                    fullWidth
                    //disable button if match is in the past or server is already started or starting. server should be started only once and it should be possible 2 minutes before the match starts
                    disabled={
                        isStartServer ||
                        moment().isBefore(moment(matchTime).subtract(5, 'minutes')) ||
                        moment().isAfter(moment(matchTime).add(5, 'minutes'))
                    }
                    onClick={async () => {
                        setStartServer(true);
                        try {
                            await axios.put('/api/team/server/start/match', {
                                TeamID: teamLower.IsOnDemandServer
                                    ? teamLower.ID
                                    : teamHigher.IsOnDemandServer
                                    ? teamHigher.ID
                                    : null,
                            });
                            await new Promise((resolve) => setTimeout(resolve, 5000));
                            queueMessage(
                                'success',
                                'A new server has been requested. This take a few seconds.'
                            )(dispatch);
                        } catch (e) {
                            handleError(e)(dispatch);
                        }
                        setStartServer(false);
                    }}
                >
                    Start Server
                </Button>
                <Typography variant="caption" color={'textSecondary'}>
                    The button enables 5 minutes before the match starts and disables 5 minutes after the match starts.
                </Typography>
            </>
        );
    }
    return null;
}

export default connect(
    (state) => ({
        timezone: state.app.profile ? state.app.profile.Settings.LocalTimezone : moment.tz.guess(),
        profile: state.app.profile,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                confirmDeleteMatch,
                subscribe,
                unsubscribe,
                addMessages,
            },
            dispatch
        )
)(Match);
