import React, { useEffect, useMemo } from 'react';
import { Alert, Box, IconButton, Snackbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LinkedAccountRow from 'components/auth/LinkedAccountRow';
import { AccountProvider } from '../../model/profile';
import { useSelector } from 'react-redux';

const styles = {
    container: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        flexGrow: 1,
    },
    heading: {
        color: 'orange',
    },
    box: {
        marginTop: 1,
        '> div': {
            marginTop: 1,
            borderTop: `1px solid white`,
            paddingTop: 1,
            ':first-of-type': {
                paddingTop: 0,
                borderTop: 0,
            },
        },
    },
};

const recommendedProviders: AccountProvider[] = ['steam', 'riot', 'discord'];

const optionalProviders: AccountProvider[] = ['battlenet', 'twitch'];

function ProviderLinkAlert() {
    const profile = useSelector((state: any) => state.app.profile);
    const [isOpen, setOpen] = React.useState(false);
    const [dismissCount, setDismissCount] = React.useState(0);
    const [firstDismissDate, setFirstDismissDate] = React.useState<Date | null>(null);

    const accountsByProvider = useMemo(() => {
        const result: any = {};
        for (const account of profile.Accounts) {
            result[account.Provider] = account;
        }
        return result;
    }, [profile]);

    useEffect(() => {
        const hideAlert = () => {
            setOpen(false);
            localStorage.setItem('alert.provider_link.hide', '1');
        };

        const dismissCountString = localStorage.getItem('alert.provider_link.dismissCount');
        const storedDismissCount = dismissCountString ? parseInt(dismissCountString, 10) : 0;
        const firstDismissDateString = localStorage.getItem('alert.provider_link.firstDismissDate');
        const storedFirstDismissDate = firstDismissDateString ? new Date(firstDismissDateString) : null;

        if (storedDismissCount !== 0 && storedFirstDismissDate) {
            setDismissCount(storedDismissCount);
            setFirstDismissDate(storedFirstDismissDate);

            const now = new Date();
            const timeDiff = now.getTime() - storedFirstDismissDate.getTime();
            const daysDiff = timeDiff / (1000 * 3600 * 24);

            if (storedDismissCount >= 6 || daysDiff >= 90) {
                hideAlert();
            } else if (storedDismissCount >= 3 || daysDiff >= 30) {
                setOpen(true);
            } else if (storedDismissCount * 7 === daysDiff) {
                setOpen(true);
            }
        } else {
            setOpen(true);
            setFirstDismissDate(new Date());
        }
    }, []);

    const handleDismiss = () => {
        setDismissCount(dismissCount + 1);
        localStorage.setItem('alert.provider_link.dismissCount', String(dismissCount + 1));
        localStorage.setItem('alert.provider_link.firstDismissDate', firstDismissDate!.toISOString());
        setOpen(false);
    };

    return (
        <Snackbar open={isOpen} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} sx={{ marginTop: '40px' }}>
            <Alert
                severity="info"
                elevation={5}
                action={
                    <IconButton key="close" aria-label="Close" color="inherit" onClick={handleDismiss} size="small">
                        <CloseIcon />
                    </IconButton>
                }
            >
                <Typography sx={styles.heading}>Make sure to link all your accounts.</Typography>
                <Box sx={styles.box}>
                    <Typography color="textPrimary">Recommended</Typography>
                    {recommendedProviders.map((provider: AccountProvider) => {
                        return (
                            <LinkedAccountRow
                                key={provider}
                                provider={provider}
                                account={accountsByProvider[provider] ?? null}
                                size="small"
                            />
                        );
                    })}
                </Box>

                <Box sx={styles.box}>
                    <Typography color="textPrimary">Optional</Typography>
                    {optionalProviders.map((provider: AccountProvider) => {
                        return (
                            <LinkedAccountRow
                                key={provider}
                                provider={provider}
                                account={accountsByProvider[provider] ?? null}
                                size="small"
                            />
                        );
                    })}
                </Box>
            </Alert>
        </Snackbar>
    );
}

export default ProviderLinkAlert;
