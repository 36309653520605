import React from 'react';
import { Avatar, useTheme } from '@mui/material';

function TeamLogo({ url, sx = {}, ...otherProps }) {
    const theme = useTheme();

    if (url === '') {
        url = 'https://s3-eu-west-1.amazonaws.com/pracc-static/images/unknown_team_full.jpg';

        if (theme.palette.mode === 'dark') {
            return <Avatar src={url} sx={{ ...sx, border: '1px solid rgba(255, 255, 255, 0.2)' }} />;
        }
    }

    return <Avatar sx={sx} {...otherProps} src={url} />;
}

export default TeamLogo;
