const reportReason = [
    { number: 0, label: 'Cheating', image: undefined },
    { number: 1, label: 'Trolling', image: undefined },
    { number: 2, label: 'Unsportsmanlike behaviour', image: undefined },
    { number: 3, label: "Didn't show up", image: undefined },
    { number: 4, label: 'Missbehaving', image: undefined },
];

const ratingReason = [
    { number: 0, label: 'Friendly', image: undefined },
    { number: 1, label: 'On Time', image: undefined },
    { number: 2, label: 'Played Normal', image: undefined },
    { number: 3, label: 'Cheating', image: undefined },
    { number: 4, label: 'Trolling', image: undefined },
    { number: 5, label: 'Unsportsmanlike behaviour', image: undefined },
    { number: 6, label: "Didn't show up", image: undefined },
    { number: 7, label: 'Misbehaving', image: undefined },
];

const indexedReasons = {};

function getIndexedReasons(reportId) {
    if (indexedReasons.hasOwnProperty(reportId)) {
        return indexedReasons[reportId];
    }
    indexedReasons[reportId] = {};
    for (const reason of getReasons(reportId)) {
        indexedReasons[reportId][reason.number] = reason;
    }

    return indexedReasons[reportId];
}

export function getReasons(reportId) {
    switch (reportId) {
        case 0:
            return reportReason;
        case 1:
            return ratingReason;
        default:
            return [];
    }
}

export function getReasonFromNumber(reportId, number) {
    const reasons = getIndexedReasons(reportId);
    if (reasons.hasOwnProperty(number)) {
        return reasons[number];
    }

    return {
        label: 'Unknown Reason',
        image: undefined,
        number,
    };
}

export function hasReportReasons(reportId) {
    return getReasons(reportId).length > 0;
}

export default reportReason;
