import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { retrieveAuthEvent, retrieveAuthUrl } from './utils';
import AccountLoginWaitDialog from './AccountLoginWaitDialog';
import { useDispatch, useSelector } from 'react-redux';
import WebsocketManager from '../../utils/websocket-manager';
import { handleError, queueMessage } from '../snackbar/reducer';
import { AccountProvider, Profile } from '../../model/profile';
import axios from 'axios';
import ButtonWithAsyncAction from '../ButtonWithAsyncAction';

export interface OAuthLoginButtonProps extends ButtonProps {
    provider: AccountProvider;
    authUrlParams?: Record<string, string>;
    dialogTitle?: React.ReactNode;
    onSuccess?: (profile: Profile, extraParams: URLSearchParams) => void;
}

function AccountLoginButton({
    provider,
    authUrlParams = {},
    onSuccess = () => {},
    dialogTitle,
    children,
    ...rest
}: OAuthLoginButtonProps) {
    const [isLoading, setLoading] = React.useState(false);
    const [validating, setValidating] = React.useState(false);
    const [authUrl, setAuthUrl] = React.useState('');
    // @ts-ignore
    const websocket = useSelector((state) => state.app.websocket) as WebsocketManager;
    const dispatch = useDispatch();

    async function handleAuth() {
        setAuthUrl('');
        setLoading(true);
        setValidating(false);

        try {
            const authUrl = await retrieveAuthUrl(provider, authUrlParams);
            setAuthUrl(authUrl);
            const authEvent = await retrieveAuthEvent(websocket, authUrl);
            setValidating(true);
            const res = await axios.post(authEvent.Payload.VerifyUrl);

            const params = new URLSearchParams(
                authEvent.Payload.VerifyUrl.slice(authEvent.Payload.VerifyUrl.indexOf('?') + 1)
            );
            params.delete('code');
            params.delete('state');

            setTimeout(() => {
                onSuccess(res.data as Profile, params);
            }, 1);
        } catch (e) {
            handleError(e)(dispatch);
        }
        setLoading(false);
    }

    return (
        <>
            <ButtonWithAsyncAction {...rest} disabled={isLoading} onClick={handleAuth}>
                {children}
            </ButtonWithAsyncAction>
            <AccountLoginWaitDialog
                title={dialogTitle || children}
                open={isLoading}
                validating={validating}
                authUrl={authUrl}
                onClose={() => setLoading(false)}
            />
        </>
    );
}

export default AccountLoginButton;
