import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import NotFoundPage from './NotFoundPage';
import PermissionDeniedPage from './PermissionDeniedPage';

const ProfileAwareRoute = ({
    component: Component,
    profile,
    requireAuth = false,
    requireTeam = false,
    requireAdmin = false,
    skipEmailCheck = false,
    ...rest
}) => {
    const authed = !!profile;
    const hasTeam = profile && profile.Team;
    const isAdmin = profile && profile.IsAdmin;
    const hasCompletedAccount = !!sessionStorage.getItem('account.hasCompleted');

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!skipEmailCheck && profile && !profile.IsEmailConfirmed && !hasCompletedAccount) {
                    return (
                        <Redirect
                            to={{
                                pathname: '/complete-account',
                                state: {
                                    targetPath: props.location.pathname,
                                },
                            }}
                        />
                    );
                }

                if (!authed && (requireAuth || requireTeam || requireAdmin)) {
                    return <Redirect to={{ pathname: '/login', state: { targetPath: props.location.pathname } }} />;
                }

                if (!hasTeam && requireTeam) {
                    return <Redirect to={{ pathname: '/', state: { targetPath: props.location.pathname } }} />;
                }

                if (requireAdmin && !isAdmin) {
                    return <PermissionDeniedPage />;
                }

                return <Component {...props} />;
            }}
        />
    );
};

export default ProfileAwareRoute;
