import React from 'react';
import { useAds } from './AdContextProvider';

function DisableAds() {
    const { setAdsEnabled } = useAds();

    React.useEffect(() => {
        setAdsEnabled(false);

        return () => {
            setAdsEnabled(true);
        };
    }, []);

    return null;
}

export default DisableAds;
