import React, { Component } from 'react';
import PraccPaper from '../../components/paper/PraccPaper';
import PraccPaperHeader from '../../components/paper/PraccPaperHeader';
import { Typography, Link, Box } from '@mui/material';
import playwireImage from './img/playwire.webp';

class LegalNotice extends Component {
    componentDidMount() {}

    render = () => {
        return (
            <PraccPaper>
                <PraccPaperHeader>Legal Notice</PraccPaperHeader>

                <Typography color="textSecondary">
                    This page contains legal information according to German laws. We do our best to provide all
                    information in English.
                </Typography>

                <address>
                    stonefire GmbH<br></br>Kuhbergstr. 37<br></br>34131 Kassel <br></br>Germany
                </address>

                <p>
                    For urgent inquiries (no support), please contact crisbyyy on Discord.<br></br>
                    For support requests, please use our{' '}
                    <Link target="_blank" href="https://discord.gg/dWHrM2BbFs">
                        Discord Support
                    </Link>
                    . For other inquiries please write an email to admin@pracc.com.
                </p>

                <p>Represented by: Christian Schmitt</p>

                <p>
                    Registration Court: Amtsgericht Kassel
                    <br />
                    Registration Number: HRB 16808
                </p>

                <p>VAT-ID according to §27 a UStG: DE302230359</p>

                <p>
                    Responsible for contents according to § 55 Abs. 2 RStV: Christian Schmitt, Kuhbergstr. 37, 34131
                    Kassel, Germany
                </p>

                <div style={{ textAlign: 'justify' }}>
                    <h4>Disclaimer</h4>

                    <h5 style={{ marginBottom: 0, marginTop: 10 }}>Liability for Content</h5>
                    <p>
                        We undertake every effort to keep the information on pracc.com (hereinafter "Website")
                        up-to-date, but accept no liability whatsover for the provided content. According to §7 par. 1
                        of the German Tele-Media Act (hereinafter "TMG"), our responsibility as a service provider is
                        limited to our own content on the Website.
                    </p>
                    <p>
                        Specifically according to §§8 to 10 of TMG, we are not obligated to monitor third-party
                        information which is provided by or stored on our Website. However, we shall timely remove any
                        content violating the law upon becoming aware of it. In such a case, our liability shall
                        commence no sooner than the time at which we become aware of the violating content.
                    </p>

                    <h5 style={{ marginBottom: 0, marginTop: 10 }}>Liability for Links</h5>
                    <p>
                        Our Website contains links to third-party websites. We have no influence whatsover on the
                        information provided on these websites and provide no guarantee for their correctness. The
                        respective owners of such third-party websites have sole responsibility for their websites'
                        content.
                    </p>
                    <p>
                        At the time of linking to a third-party website, we found no grounds whatsover which suggested
                        that the third-party website is in violation of the law. However, we shall timely remove a link
                        whose target website violates the law upon becoming aware of it.
                    </p>

                    <h5 style={{ marginBottom: 0, marginTop: 10 }}>Copyright</h5>
                    <p>
                        The content and works provided on the Website are governed by the copyright laws of Germany and
                        in case of third party content also by copyright laws of other countries. Duplication,
                        processing, distribution, or any form of commercialization of any material beyond the scope
                        permitted by copyright law shall require the prior written consent of the content's respective
                        author, creator or legal entity holding the copyright.
                    </p>

                    <h5 style={{ marginBottom: 0, marginTop: 10 }}>Privacy Policy</h5>
                    <p>
                        Please see our dedicated privacy policy page at{' '}
                        <Link href="/privacy">https://pracc.com/privacy</Link>.
                    </p>
                </div>
            </PraccPaper>
        );
    };
}

export default LegalNotice;
