import React from 'react';
import { GAME_ID_CSGO } from './games';

export function isAlphaPreviewTeam(team) {
    return team.IsBeta;
}

export function isDiscordPreviewTeam(profile, team) {
    if (team.DiscordServerId) {
        return true;
    }

    if (team.Groups.length < 0) {
        return false;
    }

    return true;
}
