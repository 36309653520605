interface SerializableType {
    format: () => string;
}

type ParamValue = string | Array<string> | boolean | SerializableType;

export default {
    parse: function parse(queryString: string) {
        return parseQuery(queryString);
    },
    build: function build(params: Record<string, ParamValue>) {
        return buildQuery(params);
    },
};

export function parseQuery(queryString: string): Record<string, string | string[]> {
    const query: Record<string, string | string[]> = {};
    const pairs = (queryString[0] === '?' ? queryString.slice(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        if (pair[0].endsWith('[]')) {
            const key = decodeURIComponent(pair[0].slice(0, -2));
            if (!query[key]) {
                query[key] = [];
            }
            (query[key] as string[]).push(decodeURIComponent(pair[1] || ''));
            continue;
        }

        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }

    return query;
}

export function buildQuery(params: Record<string, ParamValue>): string {
    const parts: Array<string> = [];
    for (const key in params) {
        if (!params.hasOwnProperty(key)) {
            continue;
        }

        addQueryPart(parts, encodeURIComponent(key), params[key]);
    }

    return parts.length <= 0 ? '' : '?' + parts.join('&');
}

function addQueryPart(parts: Array<string>, currentPath: string, value: ParamValue) {
    if (value === null || value === undefined) {
        return;
    }

    if (Array.isArray(value)) {
        if (value.length <= 0) {
            parts.push(`${currentPath}=[]`);
        } else {
            for (const elemValue of value) {
                addQueryPart(parts, currentPath + '[]', elemValue);
            }
        }
    } else {
        let encodedValue;
        if (typeof value === 'boolean') {
            encodedValue = value ? 'true' : 'false';
        } else if (typeof value === 'object') {
            if (typeof value.format == 'function') {
                encodedValue = value.format();
            } else {
                encodedValue = encodeURIComponent(JSON.stringify(value));
            }
        } else {
            encodedValue = encodeURIComponent(value);
        }

        parts.push(`${currentPath}=${encodedValue}`);
    }
}
