import React from 'react';
import Ramp from '../../containers/app/components/Ramp';
import { useSelector } from 'react-redux';
import { Profile } from '../../model/profile';
import Playwire from './Playwire';
import { Paper, Popover, Slide } from '@mui/material';
import PraccPaper from '../paper/PraccPaper';
import GetAddFreePopover from './GetAddFreePopover';
import { GAME_ID_CSGO } from '../../utils/games';

export type AdContextType = {
    adsEnabled: boolean;
    adsBlocked: boolean;
    bottomRailHeight: number;
    setAdsEnabled: (isEnabled: boolean) => void;
    setAdsBlocked: (isBlocked: boolean) => void;
};

const AdContext = React.createContext<AdContextType>({
    adsEnabled: true,
    adsBlocked: false,
    bottomRailHeight: 0,
    setAdsEnabled: () => {},
    setAdsBlocked: () => {},
});

export function useAds() {
    return React.useContext(AdContext);
}

export interface AdContextProviderProps {
    children?: React.ReactNode;
}

function AdContextProvider({ children }: AdContextProviderProps) {
    const [disableCounter, setDisableCounter] = React.useState(0);
    const [adsBlocked, setAdsBlocked] = React.useState(false);
    const [bottomRailHeight, setBottomRailHeight] = React.useState(0);
    const profile = useSelector((state: any) => state.app.profile) as Profile | null;

    const isEnabled = false;

    const contextValue = React.useMemo<AdContextType>(() => {
        return {
            adsEnabled: isEnabled,
            adsBlocked,
            bottomRailHeight,
            setAdsEnabled: (isEnabled) => {
                setDisableCounter((current) => current + (isEnabled ? -1 : 1));
            },
            setAdsBlocked: (isBlocked) => {
                setAdsBlocked(isBlocked);
            },
        };
    }, [isEnabled, adsBlocked, bottomRailHeight]);

    React.useEffect(() => {
        const bottomRailId = 'pw-oop-bottom_rail';
        let bottomRail: HTMLElement | null = null;

        const resizeObs = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target.id === bottomRailId) {
                    setBottomRailHeight(entry.contentRect.height);

                    const footer = document.getElementById('footer-content');
                    if (footer) {
                        footer.style.paddingBottom = `${entry.contentRect.height + 5}px`;
                    }
                }
            }
        });

        const obs = new MutationObserver((mutationList) => {
            for (const mutation of mutationList) {
                for (const [_, addedNode] of mutation.addedNodes.entries()) {
                    if (addedNode.nodeType !== 1) {
                        continue;
                    }

                    const addedElement = addedNode as HTMLElement;
                    if (addedElement.id === bottomRailId) {
                        resizeObs.observe(addedElement);
                    }
                }
            }
        });
        obs.observe(document.body, { childList: true });

        return () => {
            obs.disconnect();
            if (bottomRail) {
                resizeObs.unobserve(bottomRail);
            }
        };
    }, []);

    return (
        <AdContext.Provider value={contextValue}>
            {isEnabled && (
                <>
                    <Playwire />
                    <GetAddFreePopover />
                </>
            )}
            {children}
        </AdContext.Provider>
    );
}

export default AdContextProvider;
