import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMessage } from './reducer';
import { Alert, Box, Grow, Portal } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function CustomizedAlert({ msg }) {
    const dispatch = useDispatch();
    const [autoHide, setAutoHide] = React.useState(true);

    React.useEffect(() => {
        if (!autoHide || msg.keepOpen) {
            return;
        }

        const timeout = window.setTimeout(() => {
            deleteMessage(msg.msgId)(dispatch);
        }, 5000);

        return () => {
            clearTimeout(timeout);
        };
    }, [autoHide, msg.keepOpen]);

    return (
        <Grow unmountOnExit in={msg.show ?? true}>
            <Alert
                key={msg.msgId}
                severity={msg.variant}
                sx={{ marginBottom: 1, maxWidth: 600 }}
                onMouseEnter={() => setAutoHide(false)}
                onMouseLeave={() => setAutoHide(true)}
                action={
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        {msg.actions}
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={() => {
                                deleteMessage(msg.msgId)(dispatch);
                            }}
                            size="small"
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Box>
                }
            >
                {msg.msg}
            </Alert>
        </Grow>
    );
}

function CustomizedSnackbars() {
    const queue = useSelector((state) => state.snack.queue);

    return (
        <Portal container={document.body}>
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 70,
                    right: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    zIndex: 1400,
                }}
            >
                {queue.map((m) => (
                    <CustomizedAlert msg={m} key={m.msgId} />
                ))}
            </Box>
        </Portal>
    );
}

export default CustomizedSnackbars;
