import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';

interface User {
  NumberOfUnreadMessages: number;
}

interface Chat {
  ActingUser: User;
}

type UpdateChatsCallback = (chats: Chat[]) => Chat[];

const useUnreadChats = (initialChats: Chat[] = []) => {
  const [activeChats, setActiveChats] = useState<Chat[]>(initialChats);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasLoadError, setHasLoadError] = useState<boolean>(false);

  const applyChangedChats = useCallback((updateCb: UpdateChatsCallback) => {
    setActiveChats((prevChats) => {
      const updatedChats = updateCb(prevChats);
      updatedChats.sort((a, b) => {
        if (a.ActingUser.NumberOfUnreadMessages !== b.ActingUser.NumberOfUnreadMessages) {
          return b.ActingUser.NumberOfUnreadMessages - a.ActingUser.NumberOfUnreadMessages;
        }
        return 0;
      });
      return updatedChats;
    });
  }, []);

  const fetchActiveChats = useCallback(async () => {
    setIsLoading(true);
    setHasLoadError(false);

    try {
      const res = await axios.get('/api/profile/chats');
      applyChangedChats(() => res.data);
    } catch (error) {
      console.error("Failed to load chats", error);
      setHasLoadError(true);
    } finally {
      setIsLoading(false);
    }
  }, [applyChangedChats]);

  const unreadChatsCount = activeChats.reduce((count, chat) => {
    return count + chat.ActingUser.NumberOfUnreadMessages;
  }, 0);

  useEffect(() => {
    fetchActiveChats();
  }, [fetchActiveChats]);

  return {
    unreadChatsCount,
    applyChangedChats,
    fetchActiveChats,
    isLoading,
    hasLoadError,
  };
};

export default useUnreadChats;
