import React from 'react';
import { Box, Button, Slide, Typography } from '@mui/material';
import PraccPaper from '../paper/PraccPaper';
import PraccPaperHeader from '../paper/PraccPaperHeader';
import { getRamp } from './Playwire';
import { useDispatch, useSelector } from 'react-redux';
import { Profile } from '../../model/profile';
import axios from 'axios';
import { handleError, queueMessage } from '../snackbar/reducer';
import { IoMdHeart as HeartIcon } from 'react-icons/io';
import { red } from '@mui/material/colors';
import BoostGate from '../boost/BoostGate';
import { useAds } from './AdContextProvider';
import Link from '@mui/material/Link';

function GetAddFreePopover() {
    const [isOpen, setOpen] = React.useState(false);
    const [code, setCode] = React.useState('');
    const { adsBlocked } = useAds();
    const profile = useSelector((state: any) => state.app.profile) as Profile;
    const dispatch = useDispatch();
    const adsBlockedRef = React.useRef(adsBlocked);
    adsBlockedRef.current = adsBlocked;

    React.useEffect(() => {
        if (sessionStorage.getItem('ads.popover_closed')) {
            return;
        }

        (async () => {
            try {
                const res = await axios.post('/api/profile/reward-video/code');
                setCode(res.data.Code);
            } catch (e) {
                handleError(e)(dispatch);
            }
        })();

        const timeout = setTimeout(() => {
            if (adsBlockedRef.current || !localStorage.getItem('ads.hide')) {
                setOpen(true);
            }
        }, 10000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    if (!profile?.ID) {
        return null;
    }

    return (
        <Slide in={isOpen} direction="left">
            <Box
                sx={{
                    position: 'absolute',
                    top: 25,
                    right: 25,
                    zIndex: 100,
                    boxShadow: 20,
                }}
            >
                <PraccPaper sx={{ padding: 2, width: 400, maxWidth: '80vw', marginTop: 0 }}>
                    <PraccPaperHeader variant="h6">Help support the site you love</PraccPaperHeader>
                    <Typography sx={{ textAlign: 'justify', display: { sm: 'none', md: 'block' } }}>
                        We hope you're enjoying your time on our website! We work hard to provide you with all kinds of
                        free features, and we rely on ads to keep our site running and improving.
                    </Typography>

                    {adsBlocked && (
                        <Typography sx={{ marginTop: 1, textAlign: 'justify', display: { sm: 'none', md: 'block' } }}>
                            Please{' '}
                            <Link href="https://blog.pracc.com/how-to-unblock-ads-on-pracc-com/" target="_blank">
                                turn off your ad-blocker
                            </Link>{' '}
                            for our website. This small action will help us to continue to provide you with quality
                            services.
                        </Typography>
                    )}

                    <Typography
                        sx={{
                            marginBottom: 1,
                            marginTop: 1.5,
                            textAlign: 'justify',
                            display: { sm: 'none', md: 'flex' },
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        Thank you for supporting our website!{' '}
                        <HeartIcon color={red[400]} size={20} style={{ marginLeft: 5 }} />
                    </Typography>

                    {adsBlocked && (
                        <Button
                            sx={{ marginTop: 1 }}
                            href="https://blog.pracc.com/how-to-unblock-ads-on-pracc-com/"
                            target="_blank"
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            Disable Ad blocker
                        </Button>
                    )}
                    <Button
                        sx={{ marginTop: 1 }}
                        variant="contained"
                        disabled={code === ''}
                        fullWidth
                        onClick={() => {
                            const ramp = getRamp();
                            if (!ramp.showRewardedVideo) {
                                queueMessage(
                                    'error',
                                    'Your ad blocker seems to be active. Please disable it to view the video.'
                                )(dispatch);
                                return;
                            }

                            ramp.showRewardedVideo({
                                code,
                                userId: profile.ID,
                                callback: async ({
                                    adPlayed,
                                    rewardUser,
                                    code,
                                }: {
                                    adPlayed: boolean;
                                    rewardUser: boolean;
                                    code: string;
                                }) => {
                                    if (!adPlayed) {
                                        queueMessage(
                                            'warning',
                                            'Unfortunately, we currently do not have an video partner for your region.'
                                        )(dispatch);

                                        axios.post('/api/profile/feedback', {
                                            type: 'ads.no_reward_video',
                                        });
                                    } else if (rewardUser) {
                                        try {
                                            await axios.post('/api/profile/reward-video/redeem', {
                                                code,
                                            });
                                            queueMessage(
                                                'success',
                                                'Thanks for your support. Ads have been removed!'
                                            )(dispatch);
                                        } catch (e) {
                                            handleError(e)(dispatch);
                                        }
                                    }
                                },
                            });
                        }}
                    >
                        Watch one video to remove ads 48H
                    </Button>
                    <Typography component="div" variant="caption" color="textSecondary" align="center">
                        The video is not always available, check out if you are lucky.
                    </Typography>

                    <BoostGate type="user" featureId="ad_free">
                        <Button sx={{ marginTop: 1 }} variant="contained" color="primary" fullWidth>
                            Disable ads with boost
                        </Button>
                    </BoostGate>

                    <Button
                        onClick={() => {
                            setOpen(false);
                            sessionStorage.setItem('ads.popover_closed', '1');
                        }}
                        sx={{ marginTop: 1, marginBottom: -1 }}
                        fullWidth
                    >
                        Close
                    </Button>

                    {!adsBlocked && (
                        <Button
                            onClick={() => {
                                setOpen(false);
                                localStorage.setItem('ads.hide', '1');
                            }}
                            sx={{ marginTop: 2, marginBottom: -1 }}
                            fullWidth
                        >
                            Close and don't show again
                        </Button>
                    )}
                </PraccPaper>
            </Box>
        </Slide>
    );
}

export default GetAddFreePopover;
