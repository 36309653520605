import React from 'react';
import { Alert, Box, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonLink } from '../../components/layout/buttons';
import AccountProviderIcon from '../../components/auth/AccountProviderIcon';

function DiscordPreviewAlert() {
    const [isOpen, setOpen] = React.useState(!localStorage.getItem('alert.link_discord_server.hide'));

    function hideAlert() {
        setOpen(false);
        localStorage.setItem('alert.link_discord_server.hide', '1');
    }

    return (
        <Snackbar open={isOpen} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} sx={{ marginTop: '40px' }}>
            <Alert
                severity="info"
                elevation={5}
                action={
                    <IconButton key="close" aria-label="Close" color="inherit" onClick={() => hideAlert()} size="small">
                        <CloseIcon />
                    </IconButton>
                }
            >
                <div>
                    <Box component="span" sx={{ color: 'error.main', fontWeight: 'bold' }}>
                        NEW:
                    </Box>{' '}
                    You can now link your Discord server to your team for deeper integration.
                </div>
                <ButtonLink
                    startIcon={<AccountProviderIcon provider="discord" />}
                    variant="contained"
                    sx={{ marginTop: 1 }}
                    to={'/team/discord'}
                    onClick={() => hideAlert()}
                >
                    Link Discord Server
                </ButtonLink>
            </Alert>
        </Snackbar>
    );
}

export default DiscordPreviewAlert;
