import React from 'react';
import { Box, Button, Chip, LinearProgress, Rating, Tooltip, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { getReasonFromNumber } from '../../utils/reasons';

export interface RatingDetails {
    NumMatches: number;
    NumCancelledMatches: number;
    NumComments: number;
    RatingCounts: Record<string, number>;
    ReasonCounts: Record<string, number>;
}

interface RatingSummaryProps {
    details: RatingDetails;
}

function RatingSummary({ details }: RatingSummaryProps) {
    const [totalCount, avgRating] = React.useMemo(() => {
        let totalCount = 0;
        let totalStars = 0;
        for (const numStars in details.RatingCounts) {
            if (!Object.prototype.hasOwnProperty.call(details.RatingCounts, numStars)) {
                continue;
            }

            const count = details.RatingCounts[numStars];
            const starValue = parseInt(numStars, 10);

            totalCount += count;
            totalStars += starValue * count;
        }

        const avgRating = totalCount > 0 ? Math.round((totalStars / totalCount) * 10) / 10 : 0;

        return [totalCount, avgRating];
    }, [details]);
    const attendanceRate = Math.round(((details.NumMatches - details.NumCancelledMatches) / details.NumMatches) * 100);
    const displayedReasons = React.useMemo(() => {
        const total = details.ReasonCounts.total;

        const potentialReasons = [];
        for (const reason in details.ReasonCounts) {
            if (!Object.prototype.hasOwnProperty.call(details.ReasonCounts, reason)) {
                continue;
            }
            if (reason === 'total') {
                continue;
            }

            const count = details.ReasonCounts[reason];
            if (count >= 3 && count / total >= 0.1) {
                potentialReasons.push(reason);
            }
        }

        potentialReasons.sort((a, b) => details.ReasonCounts[b] - details.ReasonCounts[a]);

        return potentialReasons.slice(0, 3);
    }, [details]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Rating max={5} precision={0.1} value={avgRating} readOnly />
                <Typography sx={{ fontWeight: 'bold', marginLeft: 1 }}>{avgRating} of 5</Typography>
            </Box>
            <Typography variant="caption" color="textSecondary">
                {totalCount} ratings
            </Typography>

            {totalCount > 0 && (
                <Box>
                    {[...Array(5)].map((_, i) => {
                        const percentage = ((details.RatingCounts[5 - i] || 0) / totalCount) * 100;

                        return (
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} key={i}>
                                <Typography variant="caption" sx={{ width: 40 }}>
                                    {5 - i} {5 - i === 1 ? 'star' : 'stars'}
                                </Typography>
                                <LinearProgress
                                    sx={{ flexGrow: 1, marginLeft: 1 }}
                                    variant="determinate"
                                    value={percentage}
                                />
                                <Typography variant="caption" sx={{ width: 35, textAlign: 'right' }}>
                                    {Math.round(percentage)}%
                                </Typography>
                            </Box>
                        );
                    })}
                </Box>
            )}

            {displayedReasons.length > 0 && (
                <>
                    <Typography variant="caption" color="textSecondary" sx={{ marginTop: 1 }}>
                        Most frequent reasons
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            marginBottom: 1,
                        }}
                    >
                        {displayedReasons.map((reason) => (
                            <Chip
                                sx={{ marginRight: 0.5, marginTop: 0.5 }}
                                label={getReasonFromNumber(1, reason).label}
                                key={reason}
                            />
                        ))}
                    </Box>
                </>
            )}

            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 1 }}>
                <Typography sx={{ flexGrow: 1, textAlign: 'right' }}>Attendance Rate: </Typography>

                <Typography
                    sx={{
                        marginLeft: 1,
                        color:
                            attendanceRate >= 90
                                ? 'success.main'
                                : attendanceRate >= 70
                                ? 'warning.main'
                                : 'error.main',
                        fontWeight: 'bold',
                        width: 40,
                    }}
                >
                    {attendanceRate}%
                </Typography>

                <Tooltip title="How many accepted matches this team attended vs. cancelled" arrow>
                    <Typography
                        component="div"
                        sx={{
                            marginLeft: 1,
                            color: 'text.secondary',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <HelpOutlineIcon />
                    </Typography>
                </Tooltip>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 1 }}>
                <Typography sx={{ flexGrow: 1, textAlign: 'right' }}>Comments: </Typography>

                <Typography
                    sx={{
                        marginLeft: 1,
                        color: 'text.secondary',
                        fontWeight: 'bold',
                        width: 40,
                    }}
                >
                    {details.NumComments}
                </Typography>

                <Tooltip title="How many other teams provided a rating with a custom comment" arrow>
                    <Typography
                        component="div"
                        sx={{
                            marginLeft: 1,
                            color: 'text.secondary',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <HelpOutlineIcon />
                    </Typography>
                </Tooltip>
            </Box>
        </Box>
    );
}

export default RatingSummary;
