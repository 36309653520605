import styled from 'styled-components';
import { default as MUIPaper } from '@mui/material/Paper';

export const Header = styled.div`
    font-size: 30px;
    font-weight: 300;
`;

export const Paper = styled(MUIPaper)`
    padding: 20px;
`;
