import React from 'react';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import { Divider, ListItemIcon, ListItemText, Typography } from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import TeamLogo from '../../../components/TeamLogo';
import QrCodeIcon from '@mui/icons-material/QrCode';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RateIcon from '@mui/icons-material/RateReview';
import Link from '@mui/material/Link';
import { FaDiscord } from 'react-icons/fa';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from 'react-redux';
import { Profile } from '../../../model/profile';
import { browserHistory } from '../../../store';
import styled from 'styled-components';
import Icon from '../../../components/icon';
import { common } from '@mui/material/colors';
import { openMsgs, selectTeam } from '../reducer';
import { logout } from '../../login/reducer';
import TeamSelectionButton from './team-selection-button';
import { FaGift as GiftIcon } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';

const MenuBadge = styled(Badge)`
    margin-right: 15px;
`;

const MobileMenu = styled.div`
    @media (min-width: 1024px) {
        display: none;
    }
`;
export const MobileMenuIcon = styled(Icon)`
    margin-right: 15px;
    &.notification {
        margin-right: 0px;
    }
`;

interface LoggedInUserButtonProps {
    activeSectionItems?: React.ReactNode;
}

function LoggedInUserButton({ activeSectionItems }: LoggedInUserButtonProps) {
    const [isOpen, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const profile = useSelector((state: any) => state.app.profile) as Profile;
    const unreadMsgs = useSelector((state: any) => state.app.unreadMsgs);
    const dispatch = useDispatch();

    const hideStyleInSmallView = {
        '@media (min-width: 1024px) and (max-width: 1024px)': {
            display: 'none',
        },
    };

    return (
        <>
            <div
                onClick={(e) => {
                    setOpen(true);
                    setAnchorEl(e.currentTarget);
                }}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    cursor: 'pointer',
                    position: 'relative',
                }}
            >
                <div
                    style={{
                        width: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    }}
                >
                    {profile?.Avatar ? (
                        <Avatar src={profile?.Avatar} sx={{ width: 30, height: 30, marginRight: '1.20rem' }} />
                    ) : (
                        <Avatar sx={{ width: 30, height: 30, marginRight: '1.20rem' }}>
                            {profile?.PersonaName.slice(0, 1)}
                        </Avatar>
                    )}
                    <Typography sx={{ color: 'white', fontSize: '1.1rem', fontWeight: 700 }}>Me</Typography>
                </div>

                <IoIosArrowForward color="white" size={20} />
            </div>
            <Menu
                anchorEl={anchorEl}
                open={isOpen}
                onClose={() => setOpen(false)}
                PaperProps={{
                    sx: {
                        overflow: 'visible',
                        mt: '-2.5rem',
                        ml: '13rem',
                        backgroundColor: 'rgb(12, 17, 38)',
                        backgroundImage: 'radial-gradient(ellipse farthest-side, rgba(0, 111, 255, 0.08), transparent)',
                        backgroundSize: '100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        border: '2px solid #181D31',
                        boxShadow: 3,
                        borderRadius: '10px',
                        top: '13rem',
                        '& .MuiAvatar-root': {
                            width: 20,
                            height: 20,
                        },
                        '@media (max-width: 600px)': {
                            mt: '-2.5rem',
                            ml: '11rem',
                        },

                        '@media (max-width: 1024px)': {
                            maxHeight: '80vh',
                            overflow: 'auto',
                        },
                    },
                }}
            >
                {profile?.IsAdmin && (
                    <MenuItem
                        onClick={() => {
                            setOpen(false);
                            browserHistory.push('/admin');
                        }}
                    >
                        <ListItemIcon>
                            <AdminPanelSettingsIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Admin</ListItemText>
                    </MenuItem>
                )}

                <MenuItem
                    onClick={() => {
                        setOpen(false);
                        browserHistory.push('/qr');
                    }}
                >
                    <ListItemIcon>
                        <QrCodeIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>QR Code</ListItemText>
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        setOpen(false);
                        browserHistory.push('/rating/rate');
                    }}
                >
                    <ListItemIcon>
                        <RateIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Rate Opponent</ListItemText>
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        setOpen(false);
                        browserHistory.push('/settings');
                    }}
                >
                    <ListItemIcon>
                        <SettingsIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Settings</ListItemText>
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        setOpen(false);
                        browserHistory.push('/billing');
                    }}
                >
                    <ListItemIcon>
                        <AttachMoneyIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Billing</ListItemText>
                </MenuItem>

                <MenuItem component={Link} href="https://discord.gg/dWHrM2BbFs" target="_blank">
                    <ListItemIcon>
                        <FaDiscord fontSize={'small'} />
                    </ListItemIcon>
                    <ListItemText>Support</ListItemText>
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        setOpen(false);
                        logout()(dispatch);
                    }}
                >
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Sign Out</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
}

export default LoggedInUserButton;
