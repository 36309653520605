import React from 'react';
import { loadStripe, PaymentIntent } from '@stripe/stripe-js';
import { Elements, LinkAuthenticationElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Alert, Button } from '@mui/material';
import ButtonWithAsyncAction from '../../components/ButtonWithAsyncAction';
import { useTheme } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { handleError } from '../../components/snackbar/reducer';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string);

interface StripeButtonProps {
    clientSecret: string;
}

function CheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();
    const [isWorking, setWorking] = React.useState(false);
    const [error, setError] = React.useState('');
    const [isCompleted, setCompleted] = React.useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const params = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

    async function checkIntent(paymentIntent: PaymentIntent) {
        if (paymentIntent.status !== 'succeeded') {
            setWorking(false);
            setError(`Payment is not completed yet (current status: ${paymentIntent?.status}).`);
        } else {
            try {
                setWorking(true);
                await axios.put('/api/payments/stripe/intent-result', {
                    clientInstanceId: params.get('clientInstance'),
                    clientSecret: params.get('clientSecret'),
                });
                setCompleted(true);
                window?.close();
            } catch (e) {
                handleError(e)(dispatch);
            }
            setWorking(false);
        }
    }

    React.useEffect(() => {
        const params = new URLSearchParams(location.search);
        const clientSecret = params.get('payment_intent_client_secret');

        if (!stripe || !clientSecret) {
            return;
        }

        setWorking(true);
        stripe.retrievePaymentIntent(clientSecret).then((result) => {
            setWorking(false);

            if (result.error) {
                if (result.error.message) {
                    setError(result.error.message);
                } else {
                    setError('There was a payment error. Status could not be confirmed.');
                }
            } else if (result.paymentIntent) {
                checkIntent(result.paymentIntent);
            }
        });
    }, [location.search, stripe]);

    if (isCompleted) {
        return (
            <>
                <Alert severity="success" sx={{ marginBottom: 2 }}>
                    Payment result received, you can close this window now.
                </Alert>
            </>
        );
    }

    return (
        <>
            {error && (
                <Alert severity="error" sx={{ marginBottom: 2 }}>
                    {error}
                </Alert>
            )}

            <PaymentElement
                options={{
                    layout: 'tabs',
                }}
            />
            <ButtonWithAsyncAction
                sx={{ marginTop: 3 }}
                disabled={isWorking}
                color="primary"
                size="large"
                fullWidth
                variant="contained"
                onClick={async () => {
                    if (!elements) {
                        return;
                    }

                    setWorking(true);
                    setError('');
                    const result = await stripe?.confirmPayment({
                        elements,
                        confirmParams: {
                            return_url: window.location.href,
                        },
                        redirect: 'if_required',
                    });
                    setWorking(false);

                    if (result?.error) {
                        if (result.error?.message) {
                            setError(result.error.message);
                        } else {
                            setError('Failed to confirm your payment data.');
                        }
                    } else if (result?.paymentIntent) {
                        checkIntent(result.paymentIntent);
                    }
                }}
            >
                Pay Now
            </ButtonWithAsyncAction>
        </>
    );
}

function StripePaymentForm({ clientSecret }: StripeButtonProps) {
    const theme = useTheme();

    return (
        <Elements
            stripe={stripePromise}
            options={{
                clientSecret,
                appearance: {
                    theme: theme.palette.mode === 'dark' ? 'night' : 'stripe',
                },
            }}
        >
            <CheckoutForm />
        </Elements>
    );
}

export default StripePaymentForm;
