import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Profile } from '../../model/profile';
import { Boost, BoostFeature } from '../../model/boost';
import axios from 'axios';
import { handleError } from '../snackbar/reducer';
import BoostFeatureDetails from '../../containers/team/edit/components/BoostFeatureDetails';
import { hideGlobalBoostFeatureDetails } from '../../containers/app/reducer';

const noBoosts = {};

function GlobalBoostGate() {
    const dispatch = useDispatch();
    const profile = useSelector((state: any) => state.app.profile) as Profile | null;
    const { open, entityType, featureId } = useSelector((state: any) => state.app.boostFeatureDetails);
    const boostFeatures = useSelector((state: any) => state.app.boostFeatures) as Record<string, BoostFeature>;
    const boostCounts = React.useMemo<Record<string, number>>(() => {
        if (entityType === 'team') {
            return profile?.Team?.Boosts || noBoosts;
        } else if (entityType === 'user') {
            return profile?.Boosts || noBoosts;
        }

        return noBoosts;
    }, [profile, entityType]);
    const [currentOwnBoostCount, setCurrentOwnBoostCount] = React.useState(0);
    const [currentLockedBoostCount, setCurrentLockedBoostCount] = React.useState(0);
    const [availableBoostCount, setAvailableBoostCount] = React.useState(0);

    const feature = boostFeatures[featureId];
    const boostedEntity = React.useMemo(() => {
        if (entityType === 'team') {
            const teamId = profile?.Team?.ID;
            if (teamId) {
                return {
                    teamId,
                };
            }
        }
        if (entityType === 'user') {
            const userId = profile?.ID;
            if (userId) {
                return {
                    userId,
                };
            }
        }

        return {};
    }, [profile, entityType]);

    async function loadUserDetails() {
        try {
            const res = await axios.get('/api/boosts/user');
            const boosts = res.data as Array<Boost>;

            let currentCount = 0,
                lockedCount = 0,
                availableCount = 0;
            for (const boost of boosts) {
                if (boost.AppliedFeatureID === null) {
                    availableCount++;
                } else if (boost.AppliedFeatureID === featureId) {
                    if (
                        (boostedEntity.teamId && boostedEntity.teamId === boost.AppliedTeam?.ID) ||
                        (boostedEntity.userId && boostedEntity.userId === boost.AppliedUser?.ID)
                    ) {
                        currentCount++;

                        if (boost.LockedUntil) {
                            lockedCount++;
                        }
                    }
                }
            }

            setCurrentOwnBoostCount(currentCount);
            setCurrentLockedBoostCount(lockedCount);
            setAvailableBoostCount(availableCount);
        } catch (e) {
            handleError(e)(dispatch);
        }
    }

    const currentBoostCount = boostCounts[featureId] || 0;

    return (
        <BoostFeatureDetails
            hideBackdrop
            boostedEntity={boostedEntity}
            selectedBoost={feature}
            selectedBoostAnchorEl={null}
            currentBoostCount={currentBoostCount}
            currentOwnBoostCount={currentOwnBoostCount}
            currentLockedBoostCount={currentLockedBoostCount}
            availableBoostCount={availableBoostCount}
            isOpen={open}
            onClose={() => hideGlobalBoostFeatureDetails()(dispatch)}
            onUpdated={async () => {
                await loadUserDetails();
                hideGlobalBoostFeatureDetails()(dispatch);
            }}
            sx={{ minWidth: 400 }}
        />
    );
}

export default GlobalBoostGate;
