import { Avatar, AvatarProps } from '@mui/material';
import React from 'react';
import cevo from './assets/platforms/cevo.png';
import esea from './assets/platforms/esea.svg';
import esl from './assets/platforms/esl.png';
import faceit from './assets/platforms/faceit.png';
import hltv from './assets/platforms/hltv.png';
import liquipedia from './assets/platforms/liquipedia.png';
import dotabuff from './assets/platforms/dotabuff.jpg';
import opendota from './assets/platforms/opendota.jpg';
import opgg from './assets/platforms/opgg.jpg';
import vlrgg from './assets/platforms/vlrgg.png';
import leaguepedia from './assets/platforms/leaguepedia.png';
import gamersclub from './assets/platforms/gamersclub.jpg';
import eslmeisterschaft from './assets/platforms/eslmeisterschaft.svg';
import {
    GAME_ID_CSGO,
    GAME_ID_DOTA2,
    GAME_ID_LOL,
    GAME_ID_RB6,
    GAME_ID_VALORANT,
    getGameForProfile,
} from '../utils/games';
import { Profile } from '../model/profile';

const platformImages: Record<string, string> = {
    cevo,
    esea,
    esl,
    faceit,
    hltv,
    liquipedia,
    dotabuff,
    opendota,
    opgg,
    vlrgg,
    leaguepedia,
    gamersclub,
    eslmeisterschaft,
};

export const platformNames = [
    'esea',
    'esl',
    'faceit',
    'cevo',
    'hltv',
    'liquipedia',
    'eslmeisterschaft',
    'dotabuff',
    'opendota',
    'opgg',
    'vlrgg',
    'leaguepedia',
    'gamersclub',
];

export function getPlatformNamesForProfile(profile: Profile) {
    return getPlatformNamesForGameId(getGameForProfile(profile).id);
}

export function getPlatformNamesForGameId(gameId: number) {
    switch (gameId) {
        case GAME_ID_CSGO:
            return ['esea', 'esl', 'faceit', 'cevo', 'hltv', 'liquipedia', 'eslmeisterschaft', 'gamersclub'];

        case GAME_ID_DOTA2:
            return ['dotabuff', 'opendota', 'liquipedia'];

        case GAME_ID_LOL:
            return ['opgg', 'liquipedia', 'leaguepedia'];

        case GAME_ID_RB6:
            return ['liquipedia'];

        case GAME_ID_VALORANT:
            return ['liquipedia', 'vlrgg', 'gamersclub'];
        default:
            return [];
    }
}

interface PlatformAvatarProps extends AvatarProps {
    platform?: string;
}

export const PlatformAvatar = React.forwardRef<HTMLDivElement | null, PlatformAvatarProps>(
    ({ platform = 'none', ...rest }, ref) => {
        const src = platformImages[platform];
        if (!src) {
            return (
                <Avatar ref={ref} {...rest}>
                    P
                </Avatar>
            );
        }

        return <Avatar ref={ref} {...rest} src={src} />;
    }
);
