import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Alert,
    ButtonGroup,
    ButtonProps,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { Profile } from '../../model/profile';
import { handleError, QUEUE_MESSAGE } from '../snackbar/reducer';
import axios from 'axios';
import Button from '@mui/material/Button';
import BoostGate from '../boost/BoostGate';
import { PraccDialog } from '../layout/dialogs';
import Avatar from '@mui/material/Avatar';
import AddIcon from '@mui/icons-material/Add';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import IconButton from '@mui/material/IconButton';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

interface FavoriteTeam {
    ID: number;
    Name: string;
    Logo: string;
}

interface FavoriteTeamButtonProps extends ButtonProps {
    team: FavoriteTeam;
    buttonSx?: SystemStyleObject;
    isButton?: boolean;
}

function FavoriteTeamButton({ team, buttonSx = {}, isButton = false, ...rest }: FavoriteTeamButtonProps) {
    const dispatch = useDispatch();
    const profile = useSelector((state: any) => state.app.profile) as Profile;
    const currentTeam = profile?.Team;
    const [showDialog, setShowDialog] = React.useState(false);
    const [isWorking, setWorking] = React.useState(false);
    const isFavorite = React.useMemo(() => {
        if (!currentTeam) {
            return false;
        }

        return currentTeam?.FavoriteTeams?.some((t) => t.FavoriteTeamID === team.ID);
    }, [currentTeam, team]);

    React.useEffect(() => {
        if (showDialog) {
            setWorking(false);
        }
    }, [showDialog]);

    async function removeFavoriteTeam() {
        setWorking(true);
        try {
            await axios.delete('/api/team/favorite/delete', {
                data: {
                    TeamID: team.ID,
                },
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Removed team from favorites',
                variant: 'success',
            });
        } catch (e) {
            handleError(e)(dispatch);
        } finally {
            setWorking(false);
            setShowDialog(false);
        }
    }

    async function handleAddFavoriteTeam() {
        setWorking(true);
        try {
            await axios.post('/api/team/favorite/add', {
                TeamID: team.ID,
            });
            dispatch({
                type: QUEUE_MESSAGE,
                msg: 'Added team to favorites',
                variant: 'success',
            });
            setShowDialog(false);
        } catch (e) {
            handleError(e)(dispatch);
        } finally {
            setWorking(false);
        }
    }
    if (currentTeam?.ID === team.ID) {
        return null;
    }
    if (isFavorite) {
        return isButton ? (
            <Button
                variant={'contained'}
                color={'success'}
                startIcon={<FavoriteIcon />}
                onClick={() => removeFavoriteTeam()}
                sx={buttonSx}
                {...rest}
            >
                Remove Favorite
            </Button>
        ) : (
            <IconButton color="primary" onClick={() => removeFavoriteTeam()} sx={buttonSx} title={'Remove favorite'}>
                <FavoriteIcon />
            </IconButton>
        );
    }

    return (
        <>
            <BoostGate
                type={'team'}
                featureId={'favorite_list'}
                requiredPropertyCount={currentTeam?.FavoriteTeams?.length + 1}
                boxSx={buttonSx}
            >
                {isButton ? (
                    <Button
                        variant={'contained'}
                        startIcon={<FavoriteIcon />}
                        onClick={() => setShowDialog(true)}
                        {...rest}
                    >
                        Add Favorite
                    </Button>
                ) : (
                    <IconButton color={'primary'} onClick={() => setShowDialog(true)} title={'Add favorite'}>
                        <FavoriteBorderIcon />
                    </IconButton>
                )}
            </BoostGate>
            <PraccDialog open={showDialog} onClose={() => setShowDialog(false)} fullWidth>
                <DialogTitle sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    Add Favorite <Avatar src={team.Logo} sx={{ marginLeft: 1, marginRight: 1 }} />
                    {team.Name}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Alert severity="warning">Are you sure you want to add this team to your favorites?</Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup>
                        <Button
                            startIcon={<AddIcon />}
                            disabled={isWorking}
                            variant="contained"
                            color="success"
                            onClick={() => handleAddFavoriteTeam()}
                        >
                            Add
                        </Button>
                        <Button disabled={isWorking} onClick={() => setShowDialog(false)}>
                            Cancel
                        </Button>
                    </ButtonGroup>
                </DialogActions>
            </PraccDialog>
        </>
    );
}

export default FavoriteTeamButton;
